// 后端接口都是下划线链接，这里忽略驼峰校验
/* eslint-disable camelcase */
import { getJson, deleteJson, postJSON, postFormData } from 'components/api/request';
import { ApiRequest, ProductsReqBase, ResponseBase } from 'components/api/types';
import { TOPIC_TYPE, TOPIC_SCENE, SHOW_STATUS } from 'components/constants/roadmap';

export interface Group {
  id: number
  title: string
  setting: {
    color: string,
  }
  show_status: SHOW_STATUS
  topic_count: number
}


interface GetApiV3RoadmapCategoriesReq extends ProductsReqBase {
  /** 类型 */
  type?: TOPIC_TYPE,
  /** 场景 */
  scene: TOPIC_SCENE,

}
export interface GetApiV3RoadmapCategoriesRespData {
  groups: Group[]
  topic_count: number
  banners: string
  banners_stat: any
}
interface GetApiV3RoadmapCategoriesResp extends ResponseBase<{
  doing: GetApiV3RoadmapCategoriesRespData,
  done: GetApiV3RoadmapCategoriesRespData,
  closed: GetApiV3RoadmapCategoriesRespData,
}>{}

/** 产品-Roadmap-分类（类型&分组）- 获取 */
export const getApiV3RoadmapCategories: ApiRequest<
  GetApiV3RoadmapCategoriesReq,
  GetApiV3RoadmapCategoriesResp
> = ({ productId, type, scene }) => getJson({
  url: `/api/v3/${productId}/roadmap/categories`,
  data: { type, scene },
});
