import * as Sentry from '@sentry/browser';

const DSN = 'https://eefd0d241c0d44e0a3c5c1fc7c4c371a@report.url.cn/sentry/1669';

// 本地环境不上报sentry
if (process.env.NODE_ENV !== 'development') {
    Sentry.init({
        dsn: DSN,
        whitelistUrls: [
            'support.qq.com',
            'txc.qq.com',
            'tucao.oa.com',
            'oa.m.tencent.com',
            'txc.gtimg.com'
        ],
        release: process.env.BUILD_VERSION
    });
}

export default Sentry;
