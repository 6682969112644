import React from 'react';
import classNames from 'classnames';
import { TcIconRmbLine } from 'components/IconSystem';
import PropTypes from 'prop-types';
import './btn-reward.less';

export const BtnReward = (props) => (
  <div
    className={classNames('btn btn-reward')}
    tabIndex={0}
    role="button"
    onClick={props.onClick}
    onKeyPress={props.onClick}
  >
    <TcIconRmbLine />
    <b className="btn-text">赞赏</b>
    <div className="wx_reward">微信发帖才能被赞赏</div>
  </div>
);

BtnReward.propTypes = {
  onClick: PropTypes.func,
};

BtnReward.defaultProps = {
  onClick: () => console.log('onClick 未绑定'),
};
