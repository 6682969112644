/* eslint-disable no-script-url, camelcase */
import PAGE from 'components/constants/page';
import Route from 'components/util/route';
import getProductId from 'components/util/get-product-id';
import { isPc as isPC } from 'components/env';

/** roadmap 路径 */
const PATH_ROADMAP = 'roadmap';
const PATH_CHANGELOG = 'change-log';
const mobilePrefix = `/embed/phone/${getProductId()}`;

/**
 * 从 url 中解析产品 id，以及移动端的路径前缀
 * @return {object} url.prefix      移动端可能带有路径前缀，例如 phone,qq
 * @return {object} url.productId   产品id
 */
const parseURL = () => {
  // TODO 补全小程序逻辑
  if (process.env.TARO_ENV === 'weapp') {
    return {
      prefix: '',
      productId: '',
    };
  }
  const regex = /^\/\w+\/(\w+\/)?(\d+)/;

  const res = location.pathname.match(regex);

  if (!res) {
    return false;
  }
  const [prefix, platform, productId] = res;

  return { prefix, platform, productId };
};

/**
 * 修改 URL 上面的 querystring
 * @param {object} extraParams 额外的参数对象，如果有重名，会替换已有参数值
 * @return {string}
 */
const searchParams = (extraParams = {}) => {
  const params = new URLSearchParams(location.search);

  Object.keys(extraParams).forEach((key) => {
    params.set(key, extraParams[key]);
  });

  let res = params.toString();

  // 保持与 location.search 一样的返回格式
  if (res.length > 0) {
    res = `?${res}`;
  }

  return res;
};

const TAB_PAGES = [
  PAGE.PAGE_INDEX,
  PAGE.PAGE_FAQ_LIST,
  PAGE.PAGE_ROADMAP,
  PAGE.PAGE_BLOG_LIST,
  PAGE.PAGE_PROFILE,
];
/**
 * 判断路径是否是tab路径
 * 小程序中跳转到tabBar页面需要使用`switchTab`
 */
export const isTabPath = path => TAB_PAGES.some(tabPath => path.startsWith(`${tabPath}`));

export const isIndexPath = path => path.startsWith(PAGE.PAGE_INDEX);
/**
 * 生成帖子详情链接
 * @param postId
 */
export const href_post = (postId) => {
  if (process.env.TARO_ENV === 'weapp') {
    //  小程序跳转逻辑
    return `${PAGE.PAGE_POST_DETAIL}?id=${postId}`;
  }
  const url = parseURL();

  if (!url || !postId) {
    return 'javascript:;';
  }

  if (isPC) {
    return `/products/${url.productId}/post/${postId}/${location.search}`;
  }
  return `${mobilePrefix}/post/${postId}/${location.search}`;
};

/**
 * 生成个人中心链接
 * @param userId
 * @param {boolean} [isReply] 是否查看回复
 */
export const href_profile = (userId, allowTourist = true, isReply = false) => {
  // TODO 小程序跳转
  if (process.env.TARO_ENV === 'weapp') {
    return `${PAGE.PAGE_PROFILE}?id=${userId}`;
  }
  const url = parseURL();

  const extraParams = {};

  if (isReply) {
    extraParams.tab = 'reply';
  }

  if (!url || !userId) {
    if (!isPC && !allowTourist) return `/embed/${url.productId}/forbidden?previous_uri=${encodeURIComponent(location.pathname)}`;
    return 'javascript:;';
  }

  if (isPC) {
    return `/products/${url.productId}/profile/${userId}/${searchParams(extraParams)}`;
  }
  return `${mobilePrefix}/profile/${userId}/${searchParams(extraParams)}`;
};

export const hrefProfileEdit = (userId) => {
  // 小程序跳转
  if (process.env.TARO_ENV === 'weapp') {
    return PAGE.PAGE_PROFILE_EDIT;
  }

  const url = parseURL();

  if (isPC) {
    // pc不支持编辑
    return `/products/${url.productId}/profile/${userId}`;
  }

  return `${mobilePrefix}/profile_edit/${userId}/`;
};

export const hrefTxcHome = () => {
  if (process.env.TARO_ENV === 'weapp') {
    return PAGE.PAGE_INDEX;
  }

  return isPC ? '/products/1368/' : '/embed/phone/1368';
};

/**
 * 生成产品首页链接
 */
export const href_home = () => {
  if (process.env.TARO_ENV === 'weapp') {
    //  小程序跳转逻辑
    return PAGE.PAGE_INDEX;
  }
  const url = parseURL();

  if (!url) {
    return 'javascript:;';
  }

  if (isPC) {
    return `/products/${url.productId}/${location.search}`;
  }
  return `${mobilePrefix}/${location.search}`;
};

/**
 * 生成团队博客的链接
 * @param blogId
 */
export const href_blog = (blogId) => {
  if (process.env.TARO_ENV === 'weapp') {
    return `${PAGE.PAGE_BLOG_DETAIL}?id=${blogId}`;
  }
  const url = parseURL();

  if (!url) {
    return 'javascript:;';
  }

  if (isPC) {
    return `/products/${url.productId}/blog/${blogId}${location.search}`;
  }
  return `${mobilePrefix}/blog/${blogId}${location.search}`;
};

/**
 * 生成团队博客归档页
 */
export const href_blog_archive = () => {
  if (process.env.TARO_ENV === 'weapp') {
    return PAGE.PAGE_BLOG_LIST;
  }
  const url = parseURL();

  if (!url) {
    return 'javascript:;';
  }

  if (isPC) {
    return `/products/${url.productId}/blog-archive${location.search}`;
  }
  return `${mobilePrefix}/blog-archive${location.search}`;
};

/**
 * 生成问题详情链接
 * @param faqsId 问题详情Id
 */
export const href_faqs_detail = (faqsId) => {
  if (process.env.TARO_ENV === 'weapp') {
    return `${PAGE.PAGE_FAQ_DETAIL}?id=${faqsId}`;
  }
  const url = parseURL();

  if (!url) {
    return 'javascript:;';
  }

  if (isPC) {
    return `/products/${url.productId}/faqs-more?id=${faqsId}`;
  }
  return `${mobilePrefix}/faqs/${faqsId}`;
};


/**
 * 我们的故事链接
 */
export const href_out_story = () => {
  if (process.env.TARO_ENV === 'weapp') {
    return PAGE.PAGE_BLOG_TEAM;
  }
  const url = parseURL();

  if (!url) {
    return 'javascript:;';
  }

  if (isPC) {
    return `/products/${url.productId}/team/`;
  }
  return `${mobilePrefix}/team/`;
};

/**
 * 常见问题列表
 */
export const href_faqs_more = (classifyId) => {
  if (process.env.TARO_ENV === 'weapp') {
    return PAGE.PAGE_FAQ_LIST;
  }

  const url = parseURL();

  if (!url) {
    return 'javascript:;';
  }
  if (isPC) {
    return `/products/${url.productId}/faqs-more/${classifyId ? `#classifyId=${classifyId}` : ''}`;
  }
  return `${mobilePrefix}/faqs-more/${location.search}`;
};


/**
 * 功能投票
 */
export const href_roadmap = () => {
  if (process.env.TARO_ENV === 'weapp') {
    return PAGE.PAGE_ROADMAP;
  }

  const url = parseURL();

  if (!url) {
    return 'javascript:;';
  }
  if (isPC) {
    return `/products/${url.productId}/roadmap`;
  }
  return `${mobilePrefix}/roadmap/${location.search}`;
};

/**
 * 大产品首页
 */
export const hrefLargeHome = () => {
  const url = parseURL();

  if (!url) {
    return 'javascript:;';
  }
  return `${mobilePrefix}/large/`;
};

/**
 * 大产品首页-兔小巢 帖子列表
 */
export const hrefPostList = () => {
  const url = parseURL();

  if (!url) {
    return 'javascript:;';
  }

  return `${mobilePrefix}/post-list/`;
};

/**
 * 现有反馈页面
 */
export const href_new_post = (userId, allowTourist, qsObj) => {
  if (process.env.TARO_ENV === 'weapp') {
    //  小程序跳转逻辑
    // TODO 之后补上跳转地址
    return PAGE.PAGE_NEW_POST;
  }

  const url = parseURL();

  if (!userId && !isPC && !allowTourist) {
    return `/embed/${url.productId}/forbidden?previous_uri=${encodeURIComponent(location.pathname)}`;
  }

  if (!url) {
    return 'javascript:;';
  }
  let { search } = location;
  if (qsObj) {
    const querystring = `${search}&${Object
      .keys(qsObj)
      .map(key => `${key}=${qsObj[key]}`)
      .join('&')
    }`;
    search = search ? `${search}&${querystring}` : `?${querystring}`;
  }

  if (isPC) {
    return `/products/${url.productId}/new-post${search}`;
  }
  return `${mobilePrefix}/new-post${search}`;
};

/**
 * 通用产品首页
 */
export const href_general_home = () => {
  const url = parseURL();

  if (!url) {
    return 'javascript:;';
  }

  if (isPC) {
    return `/products/${url.productId}/general/`;
  }
  return `${mobilePrefix}/general/`;
};

/**
 * 兔小巢后台，只有管理员可以跳转
 * @param {Boolean} isAdmin 是否为管理员
 */
export const href_dashboard = (isAdmin = false) => {
  const productId = getProductId();

  if (!isAdmin) {
    return 'javascript:;';
  }

  const isOAHost = /oa\.com$/.test(location.host);

  /* OA 域名采用相对路径 */
  if (isOAHost) {
    return `/dashboard/home?productId=${productId}`;
  }

  return `https://txc.qq.com/dashboard/home?productId=${productId}`;
};

/**
 * 获取URL上 productID 和 postID
 * @deprecated 获取产品id和路径id使用 import { getProductId, getContentId } from 'components/util'
 */
export const href_get_id = () => {
  if (process.env.TARO_ENV === 'weapp') {
    //  小程序跳转逻辑
    return {
      productId: getProductId(),
      postId: '',
    };
  }
  // 匹配 /embed/:num 格式的URL
  const productIdReg = location.pathname.match(/(\/[\w_-]+)\/(\d+)/i);

  // 匹配 /:num/embed/:num 格式的URL
  const postIdReg = location.pathname.match(/\/(\d+)(\/[\w_-]+)\/(\d+)/i);

  const productId = productIdReg ? productIdReg[2] : null;
  const postId = postIdReg ? productIdReg[3] : null;
  return {
    productId,
    postId,
  };
};

// 产品被拉黑页面
export const hrefProductBlacklist = () => {
  if (process.env.TARO_ENV === 'weapp') {
    //  小程序跳转逻辑
    // TODO 之后补上跳转地址
    return '';
  }
  const url = parseURL();

  if (!url) {
    return 'javascript:;';
  }

  if (isPC) {
    return `/products/${url.productId}/blacklist/`;
  }
  return `${mobilePrefix}/blacklist/`;
};


// 链接跳转页
export const href_link_jump = (link) => {
  // 避免无效链接
  if (!link) {
    return '';
  }

  // 管理后台的链接直接跳转，不需要进中间页
  if (location.pathname.match(/^\/dashboard/)) {
    return link;
  }

  const AllowedHosts = /(qq\.com|tencent\.com|woa\.com|oa\.com)$/;
  const url = parseURL();

  if (!url) {
    return 'javascript:;';
  }

  let hostname = '';

  try {
    /* 如果 link 参数不是一个合法的 URL，会抛出一个 TypeError https://developer.mozilla.org/zh-CN/docs/Web/API/URL/URL */
    hostname = new URL(link).hostname;
  } catch (error) {
    return 'javascript:;';
  }

  if (AllowedHosts.test(hostname)) {
    return link;
  }

  if (isPC) {
    return `/products/${getProductId() || url.productId}/link-jump?jump=${encodeURIComponent(link)}`;
  }
  return `${mobilePrefix}/link-jump?jump=${encodeURIComponent(link)}`;
};

/**
 * 问题列表 - 分类 - 点击二级分类后跳转的问题列表页
 * @param {string} categoryId 分类ID
 */
export const href_faqs_category = (categoryId) => {
  if (process.env.TARO_ENV === 'weapp') {
    //  TODO: 小程序跳转逻辑
    return `${PAGE.PAGE_FAQ_CATEGORY}?id=${categoryId}`;
  }
  const url = parseURL();

  if (!url) {
    return 'javascript:;';
  }

  // 移动端单独页面，PC端没有，跳转到PC的问题列表页
  if (isPC) {
    return `/products/${url.productId}/faqs-more/`;
  }
  return `${mobilePrefix}/faqs-category/${categoryId}`;
};

/**
 * 问题列表 - 分类 - 点击二级分类后跳转的问题列表页
 * @param {string} faqId 分类ID
 */
export const href_faqs_list = (faqId) => {
  if (process.env.TARO_ENV === 'weapp') {
    //  TODO: 小程序跳转逻辑
    return `${PAGE.PAGE_FAQ_SINGLE}?id=${faqId}`;
  }
  const url = parseURL();

  if (!url) {
    return 'javascript:;';
  }

  // 移动端单独页面，PC端没有，跳转到PC的问题列表页
  if (isPC) {
    return `/products/${url.productId}/faqs-more/`;
  }
  return `${mobilePrefix}/faqs-list/${faqId}`;
};


/**
 * 跳转产品举报页
 */
export const href_inform = (inform_productId, productId) => {
  if (process.env.TARO_ENV === 'weapp') {
    //  小程序跳转逻辑
    return PAGE.PAGE_INDEX;
  }
  const url = parseURL();

  if (!url) {
    return 'javascript:;';
  }

  if (isPC) {
    return `/products/${inform_productId}${productId ? `?product_inform_id=${productId}` : ''}`;
  }
  return `/embed/${url.platform}${inform_productId}/new-post${productId ? `?product_inform_id=${productId}` : ''}`;
};

/**
 * CE显示bg、部门、职位信息
 */

export const ceGroupName = (group_name, post_name) => {
  const postName = post_name?.replace(/\//g, ' ');
  return `${(group_name || '')}${(group_name && postName ? '\\' : '')}${postName || ''}`;
};

/**
 * 跳转产品举报页
 */
export const hrefIWantDetail = (id) => {
  if (process.env.TARO_ENV === 'weapp') {
    //  小程序跳转逻辑
    return PAGE.PAGE_INDEX;
  }
  const url = parseURL();

  if (!url) {
    return 'javascript:;';
  }

  if (isPC) {
    return '';
  }
  return `${mobilePrefix}/want-detail/${id}${location.search}`;
};


/**
 * 话题详情页
 */
export const hrefTopicDetail = (productId, topicId) => {
  // 小程序
  if (process.env.TARO_ENV === 'weapp') {
    Route.push(`${PAGE.PAGE_TOPIC_DETAIL}?id=${topicId}`);
    return;
  }
  const url = parseURL();
  // 管理后台
  if (!url) {
    window.open(`/products/${productId}/topic-detail/${topicId}/`, '_blank');
  } else {
  // pc/mobile
    Route.push(isPC
      ? `/products/${productId}/topic-detail/${topicId}/${location.search}`
      : `/embed/phone/${productId}/topic-detail/${topicId}/${location.search}`);
  }
};

export const hrefToForbidden = () => {
  const productId = getProductId();
  return `/embed/${productId}/forbidden?previous_uri=${encodeURIComponent(location.pathname)}`;
};
export const hrefToLoginError = () => {
  const productId = getProductId();
  return `/products/${productId}/login-error${location.search}`;
};
export { default as getUrlParam } from './get-url-param';


// ----------------通用路由规划--------------------------------
const getUrl = (path) => {
  // 小程序有自己的路径管理，不用这个
  if (process.env.TARO_ENV === 'weapp') {
    return '';
  }
  const productId = getProductId();
  const prefix = isPC ? `/products/${productId}/` : `/embed/phone/${productId}/`;
  return prefix + path + location.search;
};
/**
 * 功能投票
 */
export const routeToRoadmap = () => {
  // TODO 小程序
  if (process.env.TARO_ENV === 'weapp') {
    return '';
  }
  return getUrl(PATH_ROADMAP);
};

/**
 * 更新日志
 */
export const routeToChangeLog = () => {
  // TODO 小程序
  if (process.env.TARO_ENV === 'weapp') {
    return PAGE.PAGE_CHANGE_LOG;
  }
  return getUrl(PATH_CHANGELOG);
};
