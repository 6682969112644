/* eslint-disable camelcase */
import React, { FC, useState } from 'react';
import { Radio, Loading } from 'tdesign-react';
import './style.less';
import useEffectOnce from 'components/hooks/useEffectOnce';
import { getApiV3RoadmapCategories, getApiV3RoadmapTopics } from 'components/api';
import { TOPIC_SCENE, TOPIC_TAB, TOPIC_TYPE } from 'components/constants/roadmap';
import { GetApiV3RoadmapTopicsRespData } from 'components/api/v3/roadmap/topics';
import RoadmapItem from 'components/ui/roadmap/roadmap-item-container';

interface HotRoadmapProps {
  /** 产品ID */
  productId: string;
}

interface GroupsSort {
  id: number;
  title: string;
}
type GroupTopics = {
  [groupId: number]: GetApiV3RoadmapTopicsRespData[]
}
const HotRoadmap: FC<HotRoadmapProps> = (props) => {
  const [isLoading, setIsLoading] = useState(true);
  const [activeId, setActiveId] = useState(0);
  const [groupsSort, setGroupsSort] = useState<GroupsSort[]>([]);
  const [groupTopics, setGroupTopics] = useState<GroupTopics>({});

  useEffectOnce(() => {
    getApiV3RoadmapCategories({
      productId: props.productId,
      scene: TOPIC_SCENE.TYPE_PUBLIC_BANNER_STAT,
    })
      .then((res) => {
        const groupIds = res.data.doing.groups
          // TODO: 现在没做配置展示， 做了需要过滤
          // .filter(group => group.show_status !== SHOW_STATUS.OFF)
          .map(group => ({
            id: group.id,
            title: group.title,
          }));
        setGroupsSort(groupIds);
        setActiveId(groupIds[0].id);
        setIsLoading(false);
        getTopicsByGroupId(groupIds[0].id);
      });
  });

  const getTopicsByGroupId = (groupId: number) => {
    setIsLoading(true);
    getApiV3RoadmapTopics({
      productId: props.productId,
      groupId,
      tab: TOPIC_TAB.POPULAR,
      scene: TOPIC_SCENE.STAT,
      type: TOPIC_TYPE.DOING,
    })
      .then((res) => {
        const start = 0;
        const maxTopicsLengh = 8;
        setGroupTopics({
          ...groupTopics,
          [groupId]: res.data.slice(start, maxTopicsLengh),
        });
        setIsLoading(false);
      });
  };

  const handleTabChange = (activeId) => {
    setActiveId(activeId);
    if (!groupTopics[activeId]) {
      getTopicsByGroupId(activeId);
    }
  };

  return (
    <div className="hot-roadmap">
      <header className="hot-roadmap__header">
        <h1 className="hot-roadmap__title">功能投票</h1>
        <a className="hot-roadmap__all" href={`/products/${props.productId}/roadmap`}>全部</a>
      </header>
      {activeId !== 0 && groupsSort.length > 1 && (
        <Radio.Group variant="default-filled" defaultValue={activeId} onChange={handleTabChange} size="small">
          {groupsSort.map(group => (
            <Radio.Button value={group.id} key={group.id}>{group.title}</Radio.Button>
          ))}
        </Radio.Group>
      )}
      <div className="hot-roadmap__list">
        {Array.isArray(groupTopics[activeId]) && groupTopics[activeId].map(topic => (
          <RoadmapItem
            key={topic.id}
            likeCount={topic.stat?.like_count || 0}
            contentCount={topic.stat?.content_count || 0}
            readCount={topic.stat?.read_count || 0}
            title={topic.title}
            topicId={topic.id}
            defaultLiked={false}
            productId={props.productId}
            showAdminAnswerIcon={topic.answer_post.id !== '0'}
            needToBeLoadLiked
          />
        ))}
      </div>
      <Loading loading={isLoading} text="加载中..." size="small"></Loading>

      {!isLoading && groupTopics[activeId]?.length === 0
        && (<div className="hot-roadmap__empty">该分类下没有内容</div>)}
    </div >
  );
};

export { HotRoadmap };
