import React from 'react';
import {
  href_blog as hreBlog,
  href_blog_archive as hrefBlogArchive,
  href_out_story as hrefOutStory,
} from 'components/href-helper';
import { transferDate } from 'components/util';
import PropTypes from 'prop-types';
import ourStory from '../img/our_story.jpg';
export const TeamBlog = (props) => {
  const { blogList } = props;
  if (blogList.length > 4) {
    blogList.length = 4;
  }
  return (
    <div className="team_blog">
      <div className="team_blog__topic">
        <h3 className="team_blog__topic-title">团队博客</h3>
        {
          Boolean(blogList.length)
          && <a className="team_blog__topic-link_all" href={hrefBlogArchive()} target="_blank" rel="noreferrer">全部</a>
        }
      </div>
      {props.hasStory && (
        <div className="team_blog__story">
          <a href={hrefOutStory()} target="_blank" rel="noreferrer">
            <img src={ourStory} alt="我们的故事" />
          </a>
        </div>
      )}
      <ul className="blog_list">
        {blogList.map(item => (
          <li className="blog_item" key={item.id}>
            <a href={hreBlog(item.id)} target="_blank" rel="noreferrer">
              <div className="blog_item_title">{item.title}</div>
              <span className="blog_item_note">{item.author_name} {transferDate(item.created_at)}</span>
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
};

TeamBlog.defaultProps = {
  blogList: [],
};

TeamBlog.propTypes = {
  hasStory: PropTypes.bool,
  blogList: PropTypes.array,
};
