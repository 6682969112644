import BatchAction from 'components/util/batch-action';
import { postV1UserActionDoneBatch } from 'components/api';
import { CONTENT_TYPE, USER_ACTION_TYPE } from '../v1';

interface Action {
  topicId: number;
  resolve:(value: boolean) => void
}
class BatchTopicLike {
  topics = {}
  actionList: Action[] = []
  batchAction:BatchAction

  constructor() {
    this.batchAction = new BatchAction({
      asyncAction: this.asyncAction,
      callback: this.callback,
      paramsFormat: this.paramsForamt,
    });
  }

  private paramsForamt = params => ({
    productId: params[0].productId,
    topicIds: params.map(param => param.topicId),
  })


  // 处理下话题的返回值，
  private callback = (res) => {
    this.actionList.forEach((action) => {
      const {
        topicId,
        resolve,
      } = action;
      resolve(res.data.includes(`${topicId}`));
    });
  }

  private asyncAction = params => postV1UserActionDoneBatch({
    productId: params.productId,
    contentType: CONTENT_TYPE.TOPIC,
    userActionType: USER_ACTION_TYPE.LIKE,
    contentIds: params.topicIds,
  })

  private addAction = (action:Action) => {
    this.actionList.push(action);
  }

  // 获取话题信息
  public getTopic = ({ productId, topicId }) => new Promise((resolve) => {
    // 缓存里没有当前话题就发起请求从服务端拿
    this.addAction({
      topicId,
      resolve,
    });
    this.batchAction.targetAction({ productId, topicId });
  })
}

export default new BatchTopicLike();
