import React, { Component } from 'react';
import { chunk } from 'lodash-es';
import PropTypes from 'prop-types';

import dictionary from './dictionary';

// 一行9个表情
const LINE_MAX_COUNT = 9;

import './emoji-popup.less';

class EmojiPopup extends Component {

    selectEmoji(index) {

        const { props } = this;

        props.onSelectEmoji(`[${dictionary[index]}]`, `Expression_${index + 1}`);

    }

    render() {

        return (
            <div className="emoji-popup">

                <div className="scroll-container">
                    <table>

                        <tbody>
                            {chunk(dictionary, LINE_MAX_COUNT).map((line, lineIndex) => (
                                <tr key={lineIndex} className="row">
                                    {line.map((item, colIndex) => {

                                        const index =  colIndex + lineIndex * LINE_MAX_COUNT;

                                        return (
                                            <td key={index + 1} className="col" onClick={event => this.selectEmoji(index)}>
                                                <i className={`Expression_${index + 1}`}></i>
                                            </td>

                                        );

                                    })}
                                </tr>
                            ))}
                        </tbody>

                    </table>
                </div>


            </div>
        );

    }

}

EmojiPopup.propTypes = {
    onSelectEmoji: PropTypes.func
};

EmojiPopup.defaultProps = {
    onSelectEmoji: () => console.log('onSelectEmoji 未绑定')
};

export { EmojiPopup };
