import './style.less';

/* 新icon规范 */
import ZanShang from './zanshang.svg';
import WePay from './wepay.svg';
import Emtydata from './emtydata.svg';
import Avatar from './avatar.svg';
import WorkWechat from './work_wechat.svg';
import Close from './close.svg';
import TcLikes from './tc_likes.svg';
import TcLiked from './tc_liked.svg';
import TcMore from './tc_more.svg';
import TcReward from './tc_reward.svg';
import TcEmoji from './tc_emoji.svg';
import TcTopic from './tc_topic.svg';
import TcPen from './tc_pen.svg';
import TcUploadImg from './tc_upload_img.svg';
import TcSetTop from './tc_set_top.svg';
import TcSetHide from './tc_set_hide.svg';
import TcSetShow from './tc_set_show.svg';
import TcBlackName from './tc_black_name.svg';
import TcAllowReply from './tc_allow_reply.svg';
import TcStopReply from './tc_stop_reply.svg';
import TcDelete from './tc_delete.svg';
import TcDelete2 from './tc_delete2.svg';
import TcReport from './tc_report.svg';
import TcShare from './tc_share.svg';
import TcChat from './tc_chat.svg';
import TcGoHome from './tc_go_home.svg';
import TcGoHomeDark from './tc_go_home_dark.svg';
import TcArrowTop from './tc_arrow_top.svg';
import TcCircleTrophy from './tc_circle_trophy.svg';
import TcCircleFire from './tc_circle_fire.svg';
import TcCircleThumb from './tc_circle_thumb.svg';
import TcBook from './tc_book.svg';
import TcCultivate from './tc_cultivate.svg';
import TcNext from './tc_next.svg';
import TcNextBlue from './tc_next_blue.svg';
import TcNextThin from './tc_next_thin.svg';
import TcTick from './tc_tick.svg';
import TcShut from './tc_shut.svg';
import TcInfo from './tc_info.svg';
import TcDoubt from './tc_doubt.svg';
import TcLogo from './tc_logo.svg';
import TcNotFound from './tc_not_found.svg';
import TcError from './tc_error.svg';
import TcServiceUnavailable from './tc_service_unavailable.svg';
import TcRotate from './tc_rotate.svg';
import TcRefresh from './tc_refresh.svg';
import TcExchange from './tc_exchange.svg';
import TcRealExchange from './tc_real_exchange.svg';
import TcHot from './tc_hot.svg';
import TcEsc from './tc_esc.svg';
import TcManage from './tc_manage.svg';
import TcDetails from './tc_details.svg';
import TcGoodQuestion from './tc_good_question.svg';
import TcWarning from './tc_warning.svg';
import TcAdd from './tc_add.svg';
import TcAdd2 from './tc_add2.svg';
import TcAdd3 from './tc_add3.svg';
import TcTag from './tc_tag.svg';
import TcTagM from './tc_tag_m.svg';
import TcTagStrokes from './tc_tag_strokes.svg';
import TcTagPcliked from './tc_tag-pcliked.svg';
import TcProduct from './tc_product.svg';
import TcFeedback from './tc_feedback.svg';
import TcExit from './tc_exit.svg';
import TcHome from './tc_home.svg';
import TcWeibo from './tc_weibo.svg';
import TcPostDelete from './tc_post_delete.svg';
import TcPostGood from './tc_post_good.svg';
import TcPostHide from './tc_post_hide.svg';
import TcPostReward from './tc_post_reward.svg';
import TcPostStar from './tc_post_star.svg';
import TcPostTop from './tc_post_top.svg';
import TcHelp from './tc_help.svg';
import TcEdit from './tc_edit.svg';
import TcMenu from './tc_menu.svg';
import TcFolder from './tc_folder.svg';
import TcMore2 from './tc_more2.svg';
import TcNotice from './tc_notice.svg';
import TcFaqHot from './tc_faq_hot.svg';
import TcExport from './tc_export.svg';
import TcDoubtSolid from './tc_doubt_solid.svg';
import TcInfoSolid from './tc_info_solid.svg';
import TcErrorSolid from './tc_error_solid.svg';
import TcLink from './tc_link.svg';
import TcCoding from './tc_coding.svg';
import TcTime from './tc_time.svg';
import TcEnterprise from './tc_enterprise.svg';
import TcMessage from './tc_message.svg';
import TcExit2 from './tc_exit2.svg';
import TcLogoComplete from './tc_logo_complete.svg';
import TcProductLogo from './tc_product_logo.svg';
import TcQQ from './tc_qq.svg';
import TcEnterpriseGood from './tc_enterprise_good.svg';
import TcEnterpriseWatch from './tc_enterprise_watch.svg';
import TcEnterpriseComment from './tc_enterprise_comment.svg';
import TcArrowUpLine from './arrow_up.svg';
import TcSearchMobile from './tc_search_m.svg';
import TcTapd from './tc_tapd.svg';
import TcBannerRight from './tc_banner-right.svg';
import TcBannerLeft from './tc_banner-left.svg';
import TcUploadBtn from './tc_upload_btn.svg';
import TcSearch from './tc_search.svg';
import TcCE from './tc_ce.svg';
import TcReturn from './tc_return.svg';
import TcClosure from './tc_closure.svg';
import TcProfile from './tc_profile.svg';
import TcNotification from './tc_notif.svg';
import TcProductMenu from './tc_products.svg';
import TcFilledLikes from './tc_likes_filled.svg';
import TcTop from './tc_top.svg';
import TcTopicDefault from './tc_topic-default.svg';
import TcTopicDefaultBig from './tc_topic-default-big.svg';
import TcTopicGrey from './tc_topic-grey.svg';
import TcSmileBubble from './tc_smile-bubble.svg';
import TcHelpBook from './tc_help_book.svg';
import TcHelpChat from './tc_help_chat.svg';
import TcHelpAbout from './tc_help_about.svg';
import TcTopicTriangle from './tc_topic-triangle.svg';
import TcQw from './tc_qw.svg';
import TcRoadmapDark from './txc_roadmap_dark.svg';
import TcRoadmapLight from './txc_roadmap_light.svg';
import TcRoadmapLayers from './tc_roadmap_layers.svg';
import TcRoadmapcCircle from './tc_roadmap_circle.svg';
import TcMoreLine from './tc_more_line.svg';
import TcChatLine from './tc_chat_line.svg';
import TcRmbLine from './tc_rmb_line.svg';
import TcTapdLine from './tc_tapd_line.svg';
import TcGoodLine from './tc_good_line.svg';
import TcDetailsLine from './tc_details_line.svg';
import TcEmojiLine from './tc_emoji_line.svg';
import TcPicLine from './tc_pic_line.svg';
import TcShareLine from './tc_share_line.svg';
import TcVeryGoodLine from './tc_verygood_line.svg';
import TcChatBanLine from './tc_chat-ban.svg';
import ChangeLogEntry from './change-log-entry.svg';
import Calendar from './calendar.svg';
import Comment from './comment.svg';
import Share from './share.svg';
import Vote from './vote.svg';
import Category from './category.svg';
import CategoryBlue from './category_blue.svg';
import TopicGray from './topic_gray.svg';
import TopicBlue from './topic_blue.svg';
import Close2 from './close2.svg';
import Back from './back.svg';
import TcKeyboard from './tc_keyboard.svg';
import BgCalendar from './bg-calendar.svg';
import TcIconSwap from './tc_swap.svg';

BgCalendar.displayName = 'IconBgCalendar';
TcKeyboard.displayName = 'TcIconKeyboard';
Back.displayName = 'IconBack';
Close2.displayName = 'IconClose2';
CategoryBlue.displayName = 'IconCategoryBlue';
TopicGray.displayName = 'IconTopicGray';
TopicBlue.displayName = 'IconTopicBlue';
Category.displayName = 'IconCategory';
Calendar.displayName = 'IconCalendar';
Comment.displayName = 'IconComment';
Share.displayName = 'IconShare';
Vote.displayName = 'IconVote';
ChangeLogEntry.displayName = 'IconChangeLogEntry';
ZanShang.displayName = 'IconZanShang';
WePay.displayName = 'IconWePay';
Emtydata.displayName = 'IconEmtydata';
Avatar.displayName = 'IconAvatar';
Close.displayName = 'IconClose';
WorkWechat.displayName = 'IconWorkWechat';
TcLikes.displayName = 'TcIconLikes';
TcLiked.displayName = 'TcIconLiked';
TcMore.displayName = 'TcIconMore';
TcReward.displayName = 'TcIconReward';
TcEmoji.displayName = 'TcIconEmoji';
TcTopic.displayName = 'TcIconTopic';
TcPen.displayName = 'TcIconPen';
TcUploadImg.displayName = 'TcIconUploadImg';
TcSetTop.displayName = 'TcIconSetTop';
TcSetHide.displayName = 'TcIconSetHide';
TcSetShow.displayName = 'TcIconSetShow';
TcBlackName.displayName = 'TcIconBlackName';
TcAllowReply.displayName = 'TcIconAllowReply';
TcStopReply.displayName = 'TcIconStopReply';
TcDelete.displayName = 'TcIconDelete';
TcDelete2.displayName = 'TcIconDelete2';
TcReport.displayName = 'TcIconReport';
TcShare.displayName = 'TcIconShare';
TcChat.displayName = 'TcIconChat';
TcGoHome.displayName = 'TcIconGoHome';
TcGoHomeDark.displayName = 'TcIconGoHomeDark';
TcArrowTop.displayName = 'TcIconArrowTop';
TcCircleTrophy.displayName = 'TcIconCircleTrophy';
TcCircleFire.displayName = 'TcIconCircleFire';
TcCircleThumb.displayName = 'TcIconCircleThumb';
TcBook.displayName = 'TcIconBook';
TcCultivate.displayName = 'TcIconCultivate';
TcNext.displayName = 'TcIconNext';
TcNextBlue.displayName = 'TcIconNextBlue';
TcNextThin.displayName = 'TcIconNextThin';
TcTick.displayName = 'TcIconTick';
TcShut.displayName = 'TcIconShut';
TcInfo.displayName = 'TcIconInfo';
TcDoubt.displayName = 'TcIconDoubt';
TcLogo.displayName = 'TcIconLogo';
TcNotFound.displayName = 'TcIconNotFound';
TcError.displayName = 'TcIconError';
TcServiceUnavailable.displayName = 'TcIconServiceUnavailable';
TcRotate.displayName = 'TcIconRotate';
TcRefresh.displayName = 'TcIconRefresh';
TcExchange.displayName = 'TcIconExchange';
TcRealExchange.displayName = 'TcIconRealExchange';
TcHot.displayName = 'TcIconHot';
TcEsc.displayName = 'TcIconEsc';
TcManage.displayName = 'TcIconManage';
TcDetails.displayName = 'TcIconDetails';
TcGoodQuestion.displayName = 'TcIconGoodQuestion';
TcWarning.displayName = 'TcIconWarning';
TcAdd.displayName = 'TcIconAdd';
TcAdd2.displayName = 'TcIconAdd2';
TcAdd3.displayName = 'TcIconAdd3';
TcTag.displayName = 'TcIconTag';
TcTagM.displayName = 'TcIconTagM';
TcTagStrokes.displayName = 'TcTagStrokes';
TcTagPcliked.displayName = 'TcIconTagPcliked';
TcProduct.displayName = 'TcIconProduct';
TcFeedback.displayName = 'TcIconFeedback';
TcExit.displayName = 'TcIconExit';
TcHome.displayName = 'TcIconHome';
TcWeibo.displayName = 'TcIconWeibo';
TcPostDelete.displayName = 'TcPostDelete';
TcPostGood.displayName = 'TcPostGood';
TcPostHide.displayName = 'TcPostHide';
TcPostReward.displayName = 'TcPostReward';
TcPostStar.displayName = 'TcPostStar';
TcPostTop.displayName = 'TcPostTop';
TcHelp.displayName = 'TCIconHelp';
TcEdit.displayName = 'TcIconEdit';
TcMenu.displayName = 'TcIconMenu';
TcFolder.displayName = 'TcIconFolder';
TcMore2.displayName = 'TcIconMore2';
TcNotice.displayName = 'TcIconNotice';
TcFaqHot.displayName = 'TcIconFaqHot';
TcExport.displayName = 'TcIconExport';
TcDoubtSolid.displayName = 'TcIconDoubtSolid';
TcInfoSolid.displayName = 'TcIconInfoSolid';
TcErrorSolid.displayName = 'TcIconErrorSolid';
TcLink.displayName = 'TcIconLink';
TcCoding.displayName = 'TcIconCoding';
TcTime.displayName = 'TcIconTime';
TcEnterprise.displayName = 'TcIconEnterprise';
TcMessage.displayName = 'TcIconMessage';
TcExit2.displayName = 'TcIconExit2';
TcLogoComplete.displayName = 'TcIconLogoComplete';
TcProductLogo.displayName = 'TcIconProductLogo';
TcQQ.displayName = 'TcIconQQ';
TcEnterpriseGood.displayName = 'TcIconEnterpriseGood';
TcEnterpriseWatch.displayName = 'TcIconEnterpriseWatch';
TcEnterpriseComment.displayName = 'TcIconEnterpriseComment';
TcArrowUpLine.displayName = 'TcIconEnterpriseArrowUp';
TcSearchMobile.displayName = 'TcIconSearchMobile';
TcTapd.displayName = 'TcIconTapd';
TcBannerRight.displayName = 'TcIconBannerRight';
TcBannerLeft.displayName = 'TcIconBannerLeft';
TcUploadBtn.displayName = 'TcIconUploadBtn';
TcSearch.displayName = 'TcIconSearch';
TcCE.displayName = 'TcIconCE';
TcReturn.displayName = 'TcIconReturn';
TcClosure.displayName = 'TcIconClosure';
TcProfile.displayName = 'TcIconProfile';
TcNotification.displayName = 'TcIconNotification';
TcProductMenu.displayName = 'TcIconProductMenu';
TcFilledLikes.displayName = 'TcIconFilledLikes';
TcTop.displayName = 'TcIconTop';
TcTopicDefault.displayName = 'TcIconTopicDefault';
TcTopicDefaultBig.displayName = 'TcIconTopicDefaultBig';
TcTopicGrey.displayName = 'TcIconTopicGrey';
TcSmileBubble.displayName = 'TcIconSmileBubble';
TcHelpBook.displayName = 'TcIconHelpBook';
TcHelpChat.displayName = 'TcIconHelpChat';
TcHelpAbout.displayName = 'TcIconHelpAbout';
TcTopicTriangle.displayName = 'TcIconTopicTriangle';
TcQw.displayName = 'TcIconQw';
TcRoadmapDark.displayName = 'TcIconRoadmapLight';
TcRoadmapLight.displayName = 'TcIconRoadmapLight';
TcRoadmapLayers.displayName = 'TcIconRoadmapLayers';
TcRoadmapcCircle.displayName = 'TcIconRoadmapCircle';
TcMoreLine.displayName = 'TcIconMoreLine';
TcChatLine.displayName = 'TcIconChatLine';
TcRmbLine.displayName = 'TcIconRmbLine';
TcTapdLine.displayName = 'TcIconTapdLine';
TcGoodLine.displayName = 'TcIconGoodLine';
TcDetailsLine.displayName = 'TcIconetailsLine';
TcEmojiLine.displayName = 'TcIconEmojiLine';
TcPicLine.displayName = 'TcIconPicLine';
TcShareLine.displayName = 'TcIconShareLine';
TcVeryGoodLine.displayName = 'TcIconVeryGoodLine';
TcChatBanLine.displayName = 'TcIconChatBanLine';
TcIconSwap.displayName = 'TcIconSwap';

export const IconSwap = TcIconSwap;
export const IconBgCalendar = BgCalendar;
export const IconBack = Back;
export const IconClose2 = Close2;
export const IconTopicGray = TopicGray;
export const IconTopicBlue = TopicBlue;
export const IconCategory = Category;
export const IconCategoryBlue = CategoryBlue;
export const IconComment = Comment;
export const IconCalendar = Calendar;
export const IconVote = Vote;
export const IconShare = Share;
export const IconChangeLogEntry = ChangeLogEntry;
export const IconZanShang = ZanShang;
export const IconWePay = WePay;
/** @type{React.IntrinsicElements} */
export const IconEmtydata = Emtydata;
/** @type{React.IntrinsicElements} */
export const IconWorkWechat = WorkWechat;
export const IconAvatar = Avatar;
export const IconClose = Close;
export const TcIconLikes = TcLikes;
export const TcIconLiked = TcLiked;
export const TcIconMore = TcMore;
export const TcIconReward = TcReward;
export const TcIconEmoji = TcEmoji;
export const TcIconTopic = TcTopic;
export const TcIconPen = TcPen;
export const TcIconUploadImg = TcUploadImg;
export const TcIconSetTop = TcSetTop;
export const TcIconSetHide = TcSetHide;
export const TcIconSetShow = TcSetShow;
export const TcIconBlackName = TcBlackName;
export const TcIconAllowReply = TcAllowReply;
export const TcIconStopReply = TcStopReply;
export const TcIconDelete = TcDelete;
export const TcIconDelete2 = TcDelete2;
export const TcIconReport = TcReport;
export const TcIconShare = TcShare;
export const TcIconChat = TcChat;
export const TcIconGoHome = TcGoHome;
export const TcIconGoHomeDark = TcGoHomeDark;
export const TcIconArrowTop = TcArrowTop;
export const TcIconCircleTrophy = TcCircleTrophy;
export const TcIconCircleFire = TcCircleFire;
export const TcIconCircleThumb = TcCircleThumb;
export const TcIconBook = TcBook;
export const TcIconCultivate = TcCultivate;
export const TcIconNext = TcNext;
export const TcIconNextBlue = TcNextBlue;
export const TcIconNextThin = TcNextThin;
export const TcIconTick = TcTick;
export const TcIconShut = TcShut;
export const TcIconInfo = TcInfo;
export const TcIconDoubt = TcDoubt;
export const TcIconLogo = TcLogo;
export const TcIconNotFound = TcNotFound;
export const TcIconError = TcError;
export const TcIconServiceUnavailable = TcServiceUnavailable;
export const TcIconRotate = TcRotate;
export const TcIconRefresh = TcRefresh;
export const TcIconExchange = TcExchange;
export const TcIconRealExchange = TcRealExchange;
export const TcIconHot = TcHot;
export const TcIconEsc = TcEsc;
export const TcIconManage = TcManage;
export const TcIconDetails = TcDetails;
export const TcIconGoodQuestion = TcGoodQuestion;
export const TcIconWarning = TcWarning;
export const TcIconAdd = TcAdd;
export const TcIconAdd2 = TcAdd2;
export const TcIconAdd3 = TcAdd3;
export const TcIconTag = TcTag;
export const TcIconTagM = TcTagM;
export const TcIconTagStrokes = TcTagStrokes;
export const TcIconTagPcliked = TcTagPcliked;
export const TcIconProduct = TcProduct;
export const TcIconFeedback = TcFeedback;
export const TcIconExit = TcExit;
export const TcIconHome = TcHome;
export const TcIconWeibo = TcWeibo;
export const TcIconPostDelete = TcPostDelete;
/** @type{React.IntrinsicElements} */
export const TcIconPostGood = TcPostGood;
/** @type{React.IntrinsicElements} */
export const TcIconPostHide = TcPostHide;
/** @type{React.IntrinsicElements} */
export const TcIconPostReward = TcPostReward;
/** @type{React.IntrinsicElements} */
export const TcIconPostStar = TcPostStar;
/** @type{React.IntrinsicElements} */
export const TcIconPostTop = TcPostTop;
export const TcIconHelp = TcHelp;
export const TcIconEdit = TcEdit;
export const TcIconMenu = TcMenu;
export const TcIconFolder = TcFolder;
export const TcIconMore2 = TcMore2;
export const TcIconNotice = TcNotice;
export const TcIconFaqHot = TcFaqHot;
export const TcIconExport = TcExport;
export const TcIconDoubtSolid = TcDoubtSolid;
export const TcIconInfoSolid = TcInfoSolid;
export const TcIconErrorSolid = TcErrorSolid;
export const TcIconLink = TcLink;
export const TcIconCoding = TcCoding;
export const TcIconTime = TcTime;
export const TcIconEnterprise = TcEnterprise;
export const TcIconMessage = TcMessage;
export const TcIconExit2 = TcExit2;
export const TcIconLogoComplete = TcLogoComplete;
export const TcIconProductLogo = TcProductLogo;
export const TcIconQQ = TcQQ;
export const TcIconEnterpriseGood = TcEnterpriseGood;
export const TcIconEnterpriseWatch = TcEnterpriseWatch;
/** @type{React.IntrinsicElements} */
export const TcIconEnterpriseComment = TcEnterpriseComment;
export const TcIconEnterpriseArrowUp = TcArrowUpLine;
export const TcIconSearchMobile = TcSearchMobile;
export const TcIconTapd = TcTapd;
export const TcIconBannerRight = TcBannerRight;
export const TcIconBannerLeft = TcBannerLeft;
export const TcIconUploadBtn = TcUploadBtn;
export const TcIconSearch = TcSearch;
export const TcIconCE = TcCE;
export const TcIconReturn = TcReturn;
export const TcIconClosure = TcClosure;
/** @type{React.IntrinsicElements} */
export const TcIconTop = TcTop;
export const TcIconTopicDefault = TcTopicDefault;
export const TcIconTopicDefaultBig = TcTopicDefaultBig;
export const TcIconTopicGrey = TcTopicGrey;
export const TcIconSmileBubble = TcSmileBubble;
export const TcIconHelpBook = TcHelpBook;
export const TcIconHelpChat = TcHelpChat;
export const TcIconHelpAbout = TcHelpAbout;
export const TcIconTopicTriangle = TcTopicTriangle;
export const TcIconQw = TcQw;
export const TcIconRoadmapDark = TcRoadmapDark;
export const TcIconRoadmapLight = TcRoadmapLight;
export const TcIconRoadmapLayers = TcRoadmapLayers;
export const TcIconRoadmapcCircle = TcRoadmapcCircle;

export const TcIconMoreLine = TcMoreLine;
export const TcIconChatLine = TcChatLine;
export const TcIconRmbLine = TcRmbLine;
export const TcIconTapdLine = TcTapdLine;
export const TcIconGoodLine = TcGoodLine;
export const TcIconetailsLine = TcDetailsLine;
export const TcIconEmojiLine = TcEmojiLine;
export const TcIconPicLine = TcPicLine;
export const TcIconShareLine = TcShareLine;
export const TcIconVeryGoodLine = TcVeryGoodLine;
export const TcIconChatBanLine = TcChatBanLine;
export const TcIconKeyboard = TcKeyboard;


/** @type{React.IntrinsicElements} */
export const TcIconProfile = TcProfile;
/** @type{React.IntrinsicElements} */
export const TcIconNotification = TcNotification;
/** @type{React.IntrinsicElements} */
export const TcIconProductMenu = TcProductMenu;
/** @type{React.IntrinsicElements} */
export const TcIconFilledLikes = TcFilledLikes;



/* 新icon规范 */


/** 由于小程序不支持改变svg颜色 按颜色区分引入 */
export { default as TcIconPenGray } from './tc_pen_gray.svg';
export { default as TcIconPenWhite } from './tc_pen_white.svg';

export { default as TcIconLikedDark } from './tc_liked_dark.svg';
export { default as TcIconLikedLight } from './tc_liked_light.svg';

export { default as TcIconLikesLight } from './tc_likes_light.svg';

export { default as TcIconIndexLight } from './tc_index.svg';
export { default as TcIconIndexDark } from './tc_index_dark.svg';
export { default as TcIconBlogLight } from './tc_blog.svg';
export { default as TcIconBlogDark } from './tc_blog_dark.svg';
export { default as TcIconQuestionLight } from './tc_question.svg';
export { default as TcIconQuestionDark } from './tc_question_dark.svg';
export { default as TcIconMineLight } from './tc_mine.svg';
export { default as TcIconMineDark } from './tc_mine_dark.svg';
export { default as TcIconTickSuccess } from './tc_tick_ success.svg';
