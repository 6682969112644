/** topic 类型 */
export enum TOPIC_TYPE {
  /** 进行中 */
  DOING = 'doing',
  /** 已完成 */
  DONE = 'done',
  /** 已关闭 */
  CLOSED = 'closed',
}


export enum TOPIC_SCENE {
   /** 所有类型 */
   TYPE_ALL = 'type_all',
   /** 所有类型 携带统计字段 */
   TYPE_ALL_STAT = 'type_all-stat',
    /** 公开类型 */
    TYPE_PUBLIC = 'type_public',
    TYPE_PUBLIC_STAT = 'type_public-stat',
   /** 公开类型 携带宣传语 */
   TYPE_PUBLIC_BANNER = 'type_public-banner',
   TYPE_PUBLIC_BANNER_STAT = 'type_public-banner-stat',
   /** 统计字段 */
   STAT = 'stat',
   /** 回复和统计字段 */
   ANSWER_STAT = 'answer-stat',
   ANSWER_STAT_DESC= 'answer-stat-desc',
   /** 内容 */
   DESC = 'desc',
}
/** 话题标签 */
export enum TOPIC_TAB {
   /** 最新 */
   NEWEST = 'newest',
   /**  最近变更类型*/
   TYPE_LATEST = 'type_latest',
    /** 热门  */
    POPULAR = 'popular',
}

export enum SHOW_STATUS {
   ON = 'on',
   OFF ='off'
}


export const DEFAULT_TITLE = {
  DOING_MAIN: '每一个功能都期待你的参与',
  DOING_SUB: '赞同、讨论，都将提高需求话题的优先级',
  DONE_MAIN: '看看我们已完成的功能',
  DONE_SUB: '这里可以第一时间看到我们的最新动态',
};
