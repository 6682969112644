import React, { FC } from 'react';
import './style.less';

interface BannerProps {
    /** banner图片地址 */
    bannerUrl: string;
    /** 是否是默认图片 */
    isDefaultBanner: boolean;
}
export const Banner:FC<BannerProps> = ({ isDefaultBanner, bannerUrl }) => (
  <div
    className="banner"
    style={{
      backgroundColor: '#F3FBFF',
      backgroundImage: `url(${bannerUrl})`,
    }}
  >
    {isDefaultBanner && <div className="banner--default" />}
  </div>
);
