/* eslint-disable react/prop-types, quotes */
import React, { useEffect, useState } from "react";
import classNames from "classnames";
import { Modal } from "components/modal";

import {
  apiGetAuthorizedApp,
  getApiPlatfromInfo,
  post_api_authorize_callback as postApiAuthorizeCallback,
} from "components/api";

import "./tapd-confirm.less";
import { TapdFromCreate } from "./create-tapd";
import { TapdFromRelation } from "./relation-tapd";
import { Collect } from "components/report";
import message from "@tencent/ten-design-react/lib/message";
import { PLATFORM_ID, PLATFORM_STATUS } from "components/constants/tapd";

const TAPD_CONFIRM_TYPE = {
  CREATE: "create",
  RELATION: "relation",
};

const TAPD_CONFIRM_TABS = [
  {
    type: TAPD_CONFIRM_TYPE.CREATE,
    title: "创建事项",
  },
  {
    type: TAPD_CONFIRM_TYPE.RELATION,
    title: "关联事项",
  },
];

function loadTapdProjects(productId) {
  return apiGetAuthorizedApp(productId).then((res) => {
    let apps = [];
    if (res.data && res.data.apps) {
      apps = res.data.apps
        .filter(item => item.platform_id === PLATFORM_ID.TAPD
            && item.status === PLATFORM_STATUS.ON)
        .map(item => ({
          key: item.txc_app_id,
          text: item.app_name,
        }));
    }

    return apps;
  });
}

function loadTapdPorjectTypes(productId) {
  return getApiPlatfromInfo({ productId, platformId: PLATFORM_ID.TAPD })
    .then((res) => {
      if (res.data && res.data.item_types) {
        return Object.keys(res.data.item_types).map(key => ({
          key,
          text: res.data.item_types[key],
        }));
      }

      return [];
    })
    .catch(() => []);
}

export const TapdConfirm = (props) => {
  const [active, updateActive] = useState(TAPD_CONFIRM_TYPE.CREATE);
  const [projectOptions, updateProjectOptions] = useState([]);
  const [projectTypes, updateProjectTypes] = useState([]);

  function showSubmitMessage(proType, sufix, msgType = "success") {
    const project = projectTypes.find(item => item.key === proType);
    if (project) {
      message[msgType](`${project.text}${sufix}`);
    }
  }

  // 创建事项
  function handleCreate(formData) {
    return postApiAuthorizeCallback({
      productId: props.productId,
      event: "app_item_create",
      txc_app_id: formData.project,
      txc_post_id: props.post.id,
      item_type: formData.type,
      item_title: formData.title,
      item_desc: formData.content,
      creator: props.user.nickname,
    })
      .then((res) => {
        showSubmitMessage(formData.type, "创建成功", "success");
        new Collect({
          ea: "创建工单",
          ev: res.data.id,
        }).report();
        props.onSuccess(res.data.url);
      })
      .catch(() => {
        showSubmitMessage(formData.type, "创建失败", "error");
        props.onError();
      });
  }

  function handleRelation(formData) {
    return postApiAuthorizeCallback({
      productId: props.productId,
      event: "app_item_bind",
      txc_app_id: formData.project,
      txc_post_id: props.post.id,
      item_type: formData.type,
      item_id: formData.select.key,
    })
      .then(() => {
        showSubmitMessage(formData.type, "关联成功", "success");
        new Collect({
          ea: "关联工单",
          ev: formData.select.key,
        }).report();
        props.onSuccess(formData.select.url);
      })
      .catch(() => {
        showSubmitMessage(formData.type, "关联失败", "error");
        props.onError();
      });
  }

  useEffect(() => {
    loadTapdProjects(props.productId).then((res) => {
      if (res.length) {
        updateProjectOptions(res);
      }
    });

    loadTapdPorjectTypes(props.productId).then((res) => {
      if (res.length) {
        updateProjectTypes(res);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  if (!props.open) {
    return null;
  }

  return (
    <Modal onClose={props.onClose}>
      <div className="title tapd-confirm-title">
        <i className="tapd-confirm-icon" />
        {/* <img
          src={'/static/logo/tapd_icon.svg'}
          alt="tapd"
          style={{ marginRight: 6 }}
        /> */}
        关联 TAPD 项目
      </div>
      <div className="tapd-confirm-body">
        <div className="tapd-confirm-body__tabs">
          {TAPD_CONFIRM_TABS.map(tab => (
            <button
              key={tab.type}
              className={classNames("tapd-confirm-body__tab", {
                "is-active": active === tab.type,
              })}
              onClick={() => updateActive(tab.type)}
            >
              {tab.title}
            </button>
          ))}
        </div>
        <div className="tapd-confirm-body__content">
          {active === TAPD_CONFIRM_TYPE.CREATE && (
            <TapdFromCreate
              post={props.post}
              projectOptions={projectOptions}
              projectTypes={projectTypes}
              cancel={props.onClose}
              submit={handleCreate}
              productId={props.productId}
            />
          )}
          {active === TAPD_CONFIRM_TYPE.RELATION && (
            <TapdFromRelation
              post={props.post}
              postId={props.post.id}
              productId={props.productId}
              projectOptions={projectOptions}
              projectTypes={projectTypes}
              cancel={props.onClose}
              submit={handleRelation}
            />
          )}
        </div>
      </div>
    </Modal>
  );
};

TapdConfirm.defaultProps = {
  onSuccess: () => {},
  onError: () => {},
};
