import React from 'react';
import { href_profile } from 'components/href-helper';

export const SideInfo = (props) => {
    return (
        <div className="side_info">
            {
                props.userData.user_id
                &&
                <div className="side_info_mess">
                    <div className="item"><a href={href_profile(props.userData.user_id)} className="author_link"><span>{props.like_total}</span><span>被赞同</span></a></div>
                    <div className="item"><a href={href_profile(props.userData.user_id, true)} className="author_link"><span>{props.reply_total}</span><span>回答</span></a></div>
                    <div className="item"><a href={href_profile(props.userData.user_id)} className="author_link"><span>{props.post_total}</span><span>提问</span></a></div>
                </div>
            }
            {props.children}
        </div>
    );
};

SideInfo.defaultProps = {
    like_total: 0,
    reply_total: 0,
    post_total: 0,
    userData: {}
};
