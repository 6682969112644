import { post_api_authorize_callback as postApiAuthorizeCallback } from "components/api";
import { useRef, useState } from "react";

export const useSearch = (productId) => {
  const [searchLoading, updateSearchLoading] = useState(false);
  const [searchResults, updateSearchResults] = useState([]);
  const searchingRef = useRef(null);

  function startSearch(text, postId, appId, itemType) {
    clearTimeout(searchingRef.current);
    updateSearchLoading(true);
    if (text === "") {
      updateSearchLoading(false);
      updateSearchResults([]);
      return;
    }

    searchingRef.current = setTimeout(() => {
      postApiAuthorizeCallback({
        productId,
        event: "app_item_list_show_for_search",
        txc_app_id: appId,
        txc_post_id: postId,
        item_type: itemType,
        keyword: text,
      }).then((res) => {
        if (res.data && res.data.items) {
          updateSearchResults(
            res.data.items.map((item) => ({
              text: item.title,
              key: item.id,
              status: item.status,
              search: text,
              url: item.url,
            }))
          );
          updateSearchLoading(false);
        }
      });
    }, 500);
  }

  return {
    searchLoading,
    searchResults,
    startSearch,
  };
};
