import React from 'react';
import { clipboardText } from '@tencent/tc-clipboard';
import { TcIconShareLine } from 'components/IconSystem';
import PropTypes from 'prop-types';

export const BtnShare = (props) => (
  <div
    className="btn btn-share"
    tabIndex={0}
    role="button"
    onClick={(event) => clipboardText(props.href, '复制链接成功，去粘贴分享吧。')}
    onKeyPress={(event) => clipboardText(props.href, '复制链接成功，去粘贴分享吧。')}
  >
    <TcIconShareLine />
    <b className="btn-text">分享</b>
  </div>
);

BtnShare.propTypes = {
  href: PropTypes.string,
};

BtnShare.defaultProps = {
  onClick: () => console.log('onClick 未绑定'),
  // TODO 处理小程序分享逻辑
  href: (location && location.href) || '',
};
