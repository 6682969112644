import React, { FC } from 'react';
import RoadmapItem from 'components/ui/roadmap/roadmap-item';
import { RoadmapItemProps } from 'components/ui/roadmap/roadmap-item/types';
import { useLike } from 'components/hooks/useLike';
import { CONTENT_TYPE, deleteV1UserAction, postV1UserAction, USER_ACTION_TYPE } from 'components/api/v1';
import useLogin from 'components/ui/layout/base-page-pc/hook/useLogin';
import { ErrorMessageMap } from 'components/api/error-map';
import { Collect } from 'components/report';


type RoadmapItemContainerProps = Omit<RoadmapItemProps, 'onLikeClick' | 'isLikeLoading' | 'liked'> & {
  /** 默认是否点赞 */
  defaultLiked?: boolean;
  /** 是否需要加载lieked状态， 不传则自动读取defaultLiked */
  needToBeLoadLiked?: boolean
  productId: string
  /** 禁用点赞 */
  disabledLike?: boolean
}

const RoadmapItemContainer: FC<RoadmapItemContainerProps> = ({
  title,
  defaultLiked,
  likeCount,
  needToBeLoadLiked,
  topicId,
  productId,
  disabledLike,
  ...restProps
}) => {
  const { showLogin } = useLogin();
  const handleLikeClick = (liked) => {
    const param = {
      productId,
      contentId: topicId,
      contentType: CONTENT_TYPE.TOPIC,
      userActionType: USER_ACTION_TYPE.LIKE,
    };
    const likeApi = liked ? deleteV1UserAction : postV1UserAction;
    new Collect({
      ec: '功能投票-PC端',
      ea: 'CLICK',
      el: '进行中-顶帖',
      ev: `${liked ? 0 : 1}`,
    }).report();
    return likeApi(param)
      .then(res => res)
      .catch((err) => {
        if (err.message === ErrorMessageMap.NEED_LOGIN
          || err.message === ErrorMessageMap.NOT_LOGIN) {
          showLogin();
        }
        throw err;
      });
  };

  const {
    liked,
    count,
    isLoading,
    updateLike,
  } = useLike({
    likeApi: handleLikeClick,
    defaultCount: likeCount,
    defaultLiked,
    needToBeLoadLiked,
    topicId,
    productId,
  });


  return (
    <RoadmapItem
      isLikeLoading={isLoading}
      disabledLike={disabledLike}
      onLikeClick={updateLike}
      title={title}
      likeCount={count}
      liked={liked}
      productId={productId}
      topicId={topicId}
      {...restProps}
    />
  );
};

export default RoadmapItemContainer;
