
/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import Form from '@tencent/ten-design-react/lib/form';
import Select from '@tencent/ten-design-react/lib/select';
import { Button } from 'tdesign-react';
import { useSearch } from './use-search';
import { TcIconSearch } from 'components/IconSystem';

const searchOptionRender = (item) => {
  const [sufix, end] = item.text.split(item.search);
  return (
    <div>
      <span className="tapd-confirm-search__result-id">
        #{item.key.substr(-9)}
      </span>
      <span>{sufix}</span>
      <span className="tapd-confirm-search__result-target">
        {item.search}
      </span>
      <span>{end}</span>
      <span>【{item.status}】</span>
    </div>
  );
};

export const TapdFromRelation = ({
  projectOptions,
  projectTypes,
  cancel,
  submit,
  productId,
  postId,
}) => {
  const [currentProject, updateCurrentProject] = useState('');
  const [currentType, updateCurrentType] = useState('');
  const [initalTitle, updateInital] = useState(false);
  const [select, updateSelect] = useState(null);

  const [submitLoading, updateSubmitLoading] = useState(false);

  const { searchLoading, searchResults, startSearch } = useSearch(productId);

  const titleErrorText = initalTitle && !select ? '请先选择需求' : '';

  function checkFormData() {
    if (!select || submitLoading) {
      updateInital(true);
      return;
    }

    const formData = {
      type: currentType,
      project: currentProject,
      select,
    };

    const next = submit(formData);

    if (next && next.then) {
      updateSubmitLoading(true);
      next.then(() => updateSubmitLoading(false));
    }
  }

  useEffect(() => {
    if (projectOptions.length) updateCurrentProject(projectOptions[0].key);
  }, [projectOptions]);

  useEffect(() => {
    if (projectTypes.length) updateCurrentType(projectTypes[0].key);
  }, [projectTypes]);

  return (
    <>
      <Form
        layout="inline"
        labelWidth={76}
        className="tapd-confirm-body__form"
      >
        <Form.Item
          label="TAPD 项目"
          className="tapd-confirm-body__form-inline"
        >
          <Select
            loading={!projectOptions.length}
            options={projectOptions}
            value={currentProject}
            optionKey={data => data.key}
            optionText={data => data.text}
            onChange={v => updateCurrentProject(v)}
          />
        </Form.Item>
        <Form.Item
          label="事项类别"
          className="tapd-confirm-body__form-inline"
        >
          <Select
            options={projectTypes}
            value={currentType}
            optionKey={data => data.key}
            optionText={data => data.text}
            onChange={v => updateCurrentType(v)}
          />
        </Form.Item>
      </Form>
      <Form labelWidth={76} className="tapd-confirm-body__form">
        <Form.Item label="选择事项" required error={titleErrorText}>
          <Select
            loading={searchLoading}
            placeholder="搜索的任务名称"
            options={searchResults}
            optionKey="key"
            optionText="text"
            onBlur={() => updateInital(true)}
            onChange={(_, item) => updateSelect(item)}
            onFilter={text => startSearch(
              text,
              postId,
              currentProject,
              currentType,
            )
            }
            renderOption={searchOptionRender}
            suffixIcon={<TcIconSearch />}
          />
        </Form.Item>
      </Form>

      <div
        className="tapd-confirm-body__form-footer"
        style={{ marginTop: 218 }}
      >
        <Button onClick={cancel} theme="minor" size="small">
          取消
        </Button>
        <Button
          size="small"
          theme="primary"
          loading={submitLoading}
          onClick={checkFormData}
        >
          保存
        </Button>
      </div>
    </>
  );
};
