import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

export const PostButton = props => (
  <button className={classnames('post_button', props.className)} onClick={props.onClick}>
    我要发言
  </button>
);

PostButton.propTypes = {
  onClick: PropTypes.func,
  className: PropTypes.string,
};
