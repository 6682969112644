import { defineEnum } from 'components/util/enum';

// 第三方平台ID
export const PLATFORM_ID = {
  CODING: 10002,
  TAPD: 20002,
};

// 第三方平台状态
export const PLATFORM_STATUS = {
  ON: 'on',
  OFF: 'off',
};

// 枚举
export const PLATEFORM_ENUM = defineEnum(PLATFORM_ID);
