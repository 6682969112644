import { ENTERPRISE_VERSION } from 'components/constants/enterprise';
import { getUrlParam } from 'components/href-helper';
import vercodeScriptLoader from './import-vercode-script';
/**
 * 转换日期
 * 2018-11-12 17:20:35 => 11月12日
 */
/* global TencentCaptcha captcha1:true */
/* eslint no-undef: "error" */
export const transferDate = (date) => {
  /** 苹果下会因为中间带空格,而导致 nan */
  const time = new Date(date.replace(/-/g, '/').split(' '));

  const now = new Date();

  const year = now.getFullYear() === time.getFullYear() ? '' : `${time.getFullYear()}年`;

  return `${year}${time.getMonth() + 1}月${time.getDate()}日`;
};

// 滚动条在Y轴上的滚动距离

export function getScrollTop() {
  let scrollTop = 0; let bodyScrollTop = 0; let
    documentScrollTop = 0;
  if (document.body) {
    bodyScrollTop = document.body.scrollTop;
  }
  if (document.documentElement) {
    documentScrollTop = document.documentElement.scrollTop;
  }
  scrollTop = (bodyScrollTop - documentScrollTop > 0) ? bodyScrollTop : documentScrollTop;
  return scrollTop;
}

// 文档的总高度

export function getScrollHeight() {
  let scrollHeight = 0; let bodyScrollHeight = 0; let
    documentScrollHeight = 0;
  if (document.body) {
    bodyScrollHeight = document.body.scrollHeight;
  }
  if (document.documentElement) {
    documentScrollHeight = document.documentElement.scrollHeight;
  }
  scrollHeight = (bodyScrollHeight - documentScrollHeight > 0) ? bodyScrollHeight : documentScrollHeight;
  return scrollHeight;
}

// 浏览器视口的高度

export function getWindowHeight() {
  let windowHeight = 0;
  if (document.compatMode === 'CSS1Compat') {
    windowHeight = document.documentElement.clientHeight;
  } else {
    windowHeight = document.body.clientHeight;
  }
  return windowHeight;
}

const expressionWords = '[微笑][撇嘴][色][发呆][得意][流泪][害羞][闭嘴][睡][大哭][尴尬][发怒][调皮][呲牙][惊讶][难过][酷][冷汗][抓狂][吐][偷笑][愉快][白眼][傲慢][饥饿][困][惊恐][流汗][憨笑][悠闲][奋斗][咒骂][疑问][嘘][晕][疯了][衰][骷髅][敲打][再见][擦汗][抠鼻][鼓掌][糗大了][坏笑][左哼哼][右哼哼][哈欠][鄙视][委屈][快哭了][阴险][亲亲][吓][可怜][菜刀][西瓜][啤酒][篮球][乒乓][咖啡][饭][猪头][玫瑰][凋谢][嘴唇][爱心][心碎][蛋糕][闪电][炸弹][刀][足球][瓢虫][便便][月亮][太阳][礼物][拥抱][强][弱][握手][胜利][抱拳][勾引][拳头][差劲][爱你][NO][OK][爱情][飞吻][跳跳][发抖][怄火][转圈][磕头][回头][跳绳][投降]';
// http://tapd.oa.com/10117011/bugtrace/bugs/view?bug_id=1010117011056684189&url_cache_key=8eddc61366ed4bd8807c273dc0f252a2
// 避开带"[]"的字符带来无匹配表情的问题
const exoressionRegExp = /\[(\d|\D)*?\]/g;
const expressionItems = expressionWords.match(exoressionRegExp);
const expressionList = [];
const expressionMap = {};
expressionItems.forEach((item, index) => {
  const config = {
    msg: item,
    index,
    clazz: `expression-${index + 1}-2x`,
    src: `./img/Expression_${index + 1}@2x.png`,
  };
  expressionList.push(config);
  expressionMap[item] = config;
});

export const EXPRESSION_CONFIG = {
  REG_EXP: exoressionRegExp,
  LIST: expressionList,
  MAP: expressionMap,
};

/**
 * @deprecated 富文本相关使用 components/render-rich-text
 */
export const revertWordToImage = (text = '') => {
  let result = text;
  const expressionMatched = text.match(EXPRESSION_CONFIG.REG_EXP);
  if (expressionMatched && expressionMatched.length) {
    expressionMatched.forEach((expWord) => {
      const expConfigItem = EXPRESSION_CONFIG.MAP[expWord];
      if (expConfigItem) {
        // const img = '<img class=\'expression\' src=' + expConfigItem.src + ' />';

        const img = `<i class="Expression_${expConfigItem.index + 1}"></i>`;
        result = result.replace(expWord, img);
      }
    });
  }
  return result;
};

/**
 * 将 \n 换成 br 表情
 * @deprecated 富文本相关使用 components/render-rich-text
 * @param text
 */
export const revertBr = (text = '') => text.replace(/\n/g, '<br />');

/** 对象去重,key 为判断是否重复的字段 */
export const dupFilter = function (arr, key) {
  const temp = {};

  return arr.filter((item) => {
    if (!temp[item[key]]) {
      temp[item[key]] = true;

      return true;
    }
    return false;
  });
};


/**
 * 点赞数量显示
 */
export const giveLikeNumber = (num) => {
  if (num > 100000) {
    return '10w+';
  }
  if (num >= 10000) {
    return `${(num / 10000).toFixed(1)}w`;
  }
  if (num >= 1000) {
    const fixedNum = (num / 1000).toFixed(1);
    const res = fixedNum > 9.9 ? 9.9 : fixedNum;
    return `${res}k`;
  }
  return num;
};

/**
 * 客户端判断
 */
export const judgeClient = () => {
  const u = navigator.userAgent;
  const isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1;   // 判断是否是 android终端
  const isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);     // 判断是否是 iOS终端
  console.log(`是否是Android：${isAndroid}`); // true,false
  console.log(`是否是iOS：${isIOS}`);
  if (isAndroid) {
    document.body.setAttribute('id', 'android-effect');
  } else if (isIOS) {
    return 'IOS';
  } else {
    return 'PC';
  }
};

/**
 *  时间戳转化为 xxx年xxx月xxx日 xxx时xxx分xxx秒
 *  @param {string} timestamp
 *  @param {string} symbol | string
*/
export const timeTranslate = (timestamp, type) => {
  if (!timestamp) {
    return '';
  }

  // Safari 不能解析 YYYY-MM-DD hh:mm:ss，这里改成时间戳
  const date = new Date(timestamp * 1000);

  const year = date.getFullYear();
  const month = changeSingleDigits(date.getMonth() + 1);
  const day = changeSingleDigits(date.getDate());
  const hours = changeSingleDigits(date.getHours());
  const min = changeSingleDigits(date.getMinutes());
  const seconds = changeSingleDigits(date.getSeconds());

  if (type === 'symbol') {
    return `${year}-${month}-${day} ${hours}:${min}:${seconds}`;
  }

  if (type === 'day') {
    return `${month}-${day}`;
  }

  return `${year}年${month}月${day}日 ${hours}时${min}分${seconds}秒`;
};

/**
 * 个位数字前面加 0
 * @param string
 */
export const changeSingleDigits = (string) => {
  if (String.prototype.padStart) {
    return string.toString().padStart(2, '0');
  }

  return string < 10 ? `0${string}` : string;
};



/**
 * pc 文本多行省略
 * @param {el} 当前元素
 * @param {str} 文本字符串
 * @param {num} 减少的字数数量
 */

export const multipleOmit = function (el, str, num = 5) {
  for (let i = 0; i <= str.length; i++) {
    el.textContent = str.slice(0, i);
    if (el.scrollHeight > el.offsetHeight) {
      el.textContent = `${str.slice(0, i - num)}...`;
      break;
    }
  }
};



// support.qq.com 链接跳转
export const skipLinks = (item) => {
  let homeHref = `/products/${item.product_id}/${location.search}`;
  let personalHref = `/products/${item.product_id}/profile/${item.user_id}/`;
  let postHref = `/products/${item.product_id}/post/${item.f_title_id}/${location.search}`;
  const prefixUrl = 'https://support.qq.com';
  homeHref = `${prefixUrl}${homeHref}`;
  personalHref = `${prefixUrl}${personalHref}`;
  postHref = `${prefixUrl}${postHref}`;
  return {
    homeHref,
    personalHref,
    postHref,
  };
};

/**
 * 计算字符长度，支持汉字
 * 把双字节字符转化成两个*，便于计算长度
 * @param {string} str
 * @return {number}
 */
// eslint-disable-next-line no-control-regex
export const strLen = str => str.replace(/[^\x00-\xff]/g, '**').length;

/**
 * 数组对象转哈希对象  [{},{},{}]  ===> {{},{},{}}
 * 哈希对象的key是对象的id
 * @param {Array<object>} arrObj
 * @param {string} attr 哈希对象的key的值。
 */
export const toHashObj = (arrObj, attr = 'id') => {
  if (!(arrObj instanceof Array)) {
    return arrObj;
  }

  const newObj = { ...arrObj };
  Object.keys(newObj).forEach((k) => {
    const item = newObj[k];
    if (item) {
      const newKeys = item[attr];
      newObj[newKeys] = item;
      delete newObj[k];
    }
  });

  return newObj;
};

/**
 * 返回字节长度
 * @param {str} string
 * @return {number}
 */
export const strlen = (str) => {
  let len = 0;
  for (let i = 0; i < str.length; i++) {
    const c = str.charCodeAt(i);
    // 单字节加1
    if ((c >= 0x0001 && c <= 0x007e) || (0xff60 <= c && c <= 0xff9f)) {
      len += 1;
    } else {
      len += 2;
    }
  }
  return len;
};

/**
 * 帖子列表时间处理
 * @param {number} timestamp 时间戳
 * @param {string} [type] 类型
 */
export const postsTime = (timestamp, type) => {
  const today = new Date().getTime();
  const times = timestamp * 1000;
  const dateTimes = new Date(times);
  const day = today - times;

  let typeMonth; let typeDay; let
    typeYear;
  switch (type) {
  case 'symbol':
    typeMonth = '-';
    typeDay = '';
    typeYear = '-';
    break;
  case 'slash':
    typeMonth = '/';
    typeDay = '';
    typeYear = '/';
    break;
  default:
    typeMonth = '月';
    typeDay = '日';
    typeYear = '年';
    break;
  }


  const min = day / 1000 / 60;
  const hours = min / 60;
  const getMin = dateTimes.getMinutes() < 10 ? `0${dateTimes.getMinutes()}` : dateTimes.getMinutes();
  const gethours = dateTimes.getHours() < 10 ? `0${dateTimes.getHours()}:${getMin}` : `${dateTimes.getHours()}:${getMin}`;
  const getDays = dateTimes.getDate() < 10 ? `0${dateTimes.getDate()}` : dateTimes.getDate();
  const monthDay = dateTimes.getMonth() < 9 ? `0${dateTimes.getMonth() + 1}` : `${dateTimes.getMonth() + 1}`;
  const getMonth = `${monthDay}${typeMonth}${getDays}${typeDay} ${gethours}`;

  const nd = new Date();
  let days;
  let yars;
  const todayArr = [nd.getFullYear(), nd.getMonth() + 1, nd.getDate()];
  const newDateArr = [dateTimes.getFullYear(), dateTimes.getMonth() + 1, dateTimes.getDate()];

  for (let i = 0; i < todayArr.length; i++) {
    yars = todayArr[0] - newDateArr[0];

    if (newDateArr[1] >= todayArr[1]) {
      days = todayArr[2] - newDateArr[2];
    }
  }

  if (min < 1) {
    return '刚刚';
  }

  if (hours < 1) {
    return `${Math.floor(min)}分钟前`;
  }

  if (yars < 1) {
    if (days < 1) {
      return `今天${gethours}`;
    }

    if (days < 2) {
      return `昨天${gethours}`;
    }

    return getMonth;
  } if (yars >= 1) {
    return `${dateTimes.getFullYear()}${typeYear}${getMonth}`;
  }
};

/**
 * 支持的全部平台
 */
export const ALL_PLATFORM = {
  mobile: 'mobile',
  others: 'others',
};

/**
 * 获取当前用户的平台
 * @return {string}
 */
export function getPlatform() {
  if (/Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent)) {
    return ALL_PLATFORM.mobile;
  }
  return ALL_PLATFORM.others;
}

/**
     * 从 URL 的 hash 中获取指定参数的值
     * @param key
     * @return {string}
     */
export function getValueFromHash(key) {
  // 小程序统一走querystring 取参
  if (process.env.TARO_ENV === 'weapp') {
    return getUrlParam(key);
  }
  const regex = new RegExp(`${key}=(\\S+)`);
  const decodeUrl = decodeURI(location.hash);
  const res = decodeUrl.match(regex);

  if (!res) {
    return '';
  }

  // 获取的结果再通过?进行分割
  const value = res[1].split('?')[0];

  return value;
}


// 腾讯云 滑动验证
/**
 *
 * @param {string} idType captcha_app | captcha_setting_app 两个验证码校验策略不同，一个只针对产品创建，一个针对发布内容
 * @default captcha_app
 * 团队博客 ｜ 常见问题 ｜ 我们的故事 captcha_setting_app: 2061442133
 * 创建产品 captcha_app: 2089747997
 * 发帖 | 评论  2084915101
 */
export const verificationCode = (idType = 'captcha_app') => new Promise((resolve) => {
  let appId = '';

  const idMap = {
    captcha_setting_app: '2061442133',
    captcha_app: '2089747997',
    captcha_post: '2084915101',
  };

  appId = idMap[idType];

  const captcha1 = new TencentCaptcha(appId, (res) => {
    // res（用户主动关闭验证码）= {ret: 2, ticket: null}
    // res（验证成功） = {ret: 0, ticket: "String", randstr: "String"}
    if (res.ret === 0) {
      const { randstr, ticket } = res;
      resolve({ randstr, ticket });
    }
  });
  captcha1.show();
});

/**
 * @param {"captcha_app" | "captcha_setting_app" | "captcha_post"} [type] 验证码策略
 **/
export const getCaptchaAppId = (type = 'captcha_app') => {
  const idMap = {
    captcha_setting_app: '2061442133',
    captcha_app: '2089747997',
    captcha_post: '2084915101',
  };

  const appId = idMap[type];
  return appId || idMap.captcha_app;
};

/**
 *
 * @param {string} idType captcha_app | captcha_setting_app 两个验证码校验策略不同，一个只针对产品创建，一个针对发布内容
 * @default captcha_app
 * 团队博客 ｜ 常见问题 ｜ 我们的故事 captcha_setting_app: 2061442133
 * 创建产品 captcha_app: 2089747997
 * 发帖 | 评论  2084915101
 */
export const verificationCodePromise = async (idType = 'captcha_app') => {
  const appId = getCaptchaAppId(idType);

  if (typeof TencentCaptcha === 'undefined') {
    await vercodeScriptLoader();
  }

  const value = await new Promise((resolve, reject) => {
    const captcha1 = new TencentCaptcha(appId, (res) => {
      // res（用户主动关闭验证码）= {ret: 2, ticket: null}
      // res（验证成功） = {ret: 0, ticket: "String", randstr: "String"}
      if (res.ret === 0) {
        const { randstr, ticket } = res;
        resolve({ randstr, ticket });
      } else if (res.ret === 2) {
        reject(new Error('用户主动关闭验证码'));
      }
    });
    captcha1.show();
  });

  return value;
};



/**
 * 超出长度的文本替换为省略号
 * 使用中文计算，英文/符号通过固定比值换算成中文长度
 * @param {string} str 文本
 * @param {number} len 最大长度
 * @param {number} ratio 英文/符号与中文的比率
 */
export const ellipsisCharLength = (str = '', len, ratio = 2) => {
  let charLen = 0;
  let char;
  for (let i = 0; i < str.length; i++) {
    char = str.charCodeAt(i);
    if (char >= 19968 && char <= 40869) {
      charLen += ratio;
    } else {
      charLen += 1;
    }

    if (charLen > len * ratio) {
      return `${str.substr(0, i - 1)}...`;
    }
  }

  return str;
};

/**
 * 将queryString解析成对象
 * @param {string} str
 */
export const queryStringToObject = str => str.split('&').reduce((obj, item) => {
  const [key, value] = item.split('=');
  obj[key] = value;
  return obj;
}, {});

/**
 * 返回地址origin，某些情况下需要特定环境定制
 * @param {string} type normal | dev_static
 * @default normal
 */
export function getOrigin(type = 'normal', origin) {
  if (type === 'dev_static' && process.env.NODE_ENV === 'development') {
    return origin || 'https://support.qq.com';
  }
  return window.location.origin;
}


/** 生成一个随机数 */
export const getRandomNum = (min, max) => Math.floor(Math.random() * (max - min + 1) + min);


/**
 * 随机生成展示文案
 */
export const getRandomPlaceholder = () => {
  const placeholders = [
    '遇到好用或者不爽，请你聊聊吧...',
    '点赞还是吐槽，我们都很期待...',
    '等你很久了，你想和我们说点什么...',
    '每次交流都是灵感的开始...',
    '你的建议、反馈和想法会帮助更多人...',
  ];
  const index = getRandomNum(0, placeholders.length - 1);
  return placeholders[index];
};

/**
 * i want 特殊结构解构
 */
export const getIWantContent = (post) => {
  let title = '';
  let content = '';
  let solution = '';
  try {
    const obj = JSON.parse(post);
    title = obj.title;
    content = obj.content;
    solution = obj.solution;
  } catch (err) {
    console.error('getIWantContent error', err);
  }
  return { title, content, solution };
};



/**
 * tapd 的 i want 特殊结构解构
 */
export const getTapdIWantContent = (post) => {
  const { title, content, solution } = getIWantContent(post);
  let tapdCont = '';
  let tapdConts = '';
  let tapdContN = '';
  let tapdContS = '';
  tapdCont = `标题：${title};目前痛点：${content};你的想法：${solution}`;
  tapdConts = `标题：${title}\n目前痛点：${content}\n你的想法：${solution}`;
  tapdContN = `\n标题：${title}\n目前痛点：${content}\n你的想法：${solution}`;
  tapdContS = `\n目前痛点：${content}\n你的想法：${solution}`;
  return { title, tapdCont, tapdConts, tapdContN, tapdContS };
};

/**
 * 小程序富文本编辑器从数组处理成字符串
 * @param {array} textArr
 * @returns {string}
 */
export const formatWeappEditorValue = textArr => opsToString(textArr).trim();

/**
 * 小程序富文本编辑器从数组处理成字符串
 * @param {array} ops
 * @returns {string}
 */
export const opsToString = ops => ops.map((item) => {
  if (typeof item.insert === 'string') {
    return item.insert;
  }
  // insertImage传入的data参数会在取的时候在data-custom的key上
  if (item.insert.image && item.attributes) {
    const custom = item.attributes['data-custom'];
    const params = queryStringToObject(custom);
    return params.text;
  }
  return '';
}).join('');

/**
 * 小程序接口-过滤空字段
 */

export const filterParams = (obj) => {
  const newPar = {};
  Object.keys(obj).forEach((key) => {
    if ((obj[key] === 0 || obj[key]) && obj[key].toString().replace(/(^\s*)|(\s*$)/g, '') !== '') {
      newPar[key] = obj[key];
    }
  });
  return newPar;
};

/** 判断是否有顶部的提示条，方便其他地方计算header准确高度 */
export const hasRelated = (productInfo) => {
  if (!productInfo) return false;

  // 接口字段忽略大小写
  // eslint-disable-next-line camelcase
  return !!productInfo.related?.related_link
  || (productInfo.is_tencent_product && productInfo.access_type === ENTERPRISE_VERSION.PRIVATE);
};



export { default as setRootFontSize } from './set-root-font-size';
export { default as getProductId } from './get-product-id';
export { default as getContentId } from './get-content-id';
export { default as getUrlParams } from './get-url-params';
export { default as importVercodeScript } from './import-vercode-script';
export { sessionStorage, localStorage } from './storage';
export { saveString } from './string';
export { getChangeLogTitleByTimestamp, getChangeLogContent } from './change-log';
