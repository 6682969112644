/**
 * 根据时间戳的尾数来决定返回某些物料
 */
type Params<T> = {
  /** 物料 */
  material: T;
  /** 范围百分比，所有的range相加必须等于100 */
  range: number;
}[];
export const ABtestByTimestamp = <T>(params: Params<T>): T => {
  const timestamp = new Date().getTime()
    .toString();

  // 取出timestamp的尾两位数
  const latest = Number(timestamp
    .split('')
    .slice(-2)
    .join(''));

  // 从每个范围中取出对应的物料
  const { sum, material } = params.reduce(
    (result, param) => {
      const { material, range } = param;

      // 确定物料内容的范围
      const rangeLeft = result.sum;
      const rangeRight = result.sum + range;

      // 判断时间戳的尾数是否符合范围，取出物料
      if (latest >= rangeLeft && latest < rangeRight) {
        result.material = material;
      }

      // 叠加范围值
      result.sum = rangeRight;

      return result;
    },
    {
      material: (null as unknown) as T,
      sum: 0,
    },
  );

  // 简单判断下是不是等于百分百，如果不是则直接抛出异常
  if (sum !== 100) {
    throw new Error('参数中range相加必须为百分百');
  }

  return material;
};
