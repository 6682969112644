import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { TcIconMoreLine } from 'components/IconSystem';
import { withDropdown } from 'components/dropdown';

export const BtnMore = forwardRef((props, ref) => (
  <div className="btn btn-more" tabIndex={0} role="button" onClick={props.onClick} onKeyPress={props.onClick} ref={ref}>
    <TcIconMoreLine />
  </div>
));

BtnMore.displayName = 'BtnMore';
BtnMore.propTypes = {
  onClick: PropTypes.func,
};

BtnMore.defaultProps = {
  onClick() {
    console.log(`${this.displayName} onClick 未绑定`);
  },
};

export const BtnMoreWithDropdown = withDropdown(BtnMore);
