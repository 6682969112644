/* eslint-disable camelcase */
/* 部分接口需要用到 undefined 来避免发出没有值的参数名 */
/* eslint-disable no-useless-escape, camelcase, prefer-const */
/* eslint-disable  no-plusplus, no-undefined, jsx-a11y/no-static-element-interactions */
import { getJson, deleteJson, postJSON, postFormData } from './request';
import { getUrlParam } from 'components/href-helper';
import { horizon, aegis, Collect } from 'components/report';
import { filterParams } from 'components/util';

/**
 * 用户信息
 * @param productId 产品 id
 * @param  userId  用户 id
 */
export function get_api_profile({ productId, userId }) {
  return getJson({
    url: `/api/v1/${productId}/profile/${userId}`,
  });
}

/**
 * 反馈页
 * @param {number} productId  产品 id
 * @param {number} userId  用户 id
 * @param {string} [page_next_id] 下一页的游标
 */
export function get_api_profile_posts({ productId, userId, page_next_id }) {
  const data = {};
  if (page_next_id) {
    data.page_next_id = page_next_id;
  }
  return getJson({
    url: `/api/v1/${productId}/profile/${userId}/posts`,
    data,
  });
}

/**
 * 回复页
 * @param {number} productId 产品 id
 * @param {number} userId 用户 id
 * @param {string} [page_next_id] 下一页的游标
 */
export function get_api_profile_replies({ productId, userId, page_next_id }) {
  return getJson({
    url: `/api/v1/${productId}/profile/${userId}/replies`,
    data: {
      page_next_id,
    },
  });
}

/**
 * 获取指定用户的信息
 * @param productId 产品ID
 * @param userId    用户ID
 */
export function get_api_profile_detail({ productId, userId }) {
  return getJson({
    url: `/api/v1/${productId}/profile/${userId}/detail`,
  });
}

/**
 * 获取我的故事
 * @param productId
 */
export function get_api_team_new({ productId }) {
  return getJson({
    url: `/api/v1/${productId}/team_new`,
  });
}

/**
 * 给我的故事点赞
 * @param productId
 * @param {boolean} [isUnlike] 是否取消点赞
 */
export function post_api_team_like({ productId, isUnlike }) {
  return postJSON({
    url: `/api/v1/${productId}/team/like`,
    data: {
      status: isUnlike ? 'unlike' : undefined,
    },
  });
}

/**
 * 上报我的故事被阅读一次
 * @param productId
 */
export function post_api_team_read({ productId }) {
  return postJSON({
    url: `/api/v1/${productId}/team/read`,
  });
}

/**
 * 预览我们的故事
 * @param productId 产品ID
 * @param previewId 预览指定的故事 ID
 */
export function get_api_team_preview_new({ productId, previewId }) {
  return getJson({
    url: `/api/v1/${productId}/team/preview_new/${previewId}`,
  });
}

/**
 * 帖子详情
 * @param  productId 产品 id
 * @param  postId 帖子 id
 */
export function get_api_posts({ productId, postId }) {
  return getJson({
    url: `/api/v2/${productId}/posts/${postId}`,
  });
}

/**
 * 批量更新帖子状态
 * @param {{productId: string, ids: array, opt: string, val: string, forceDelete: boolean}}
 * @param {string} postId 帖子 id
 */
export function postApiV2PostsBatch({ productId, ids, opt, val, forceDelete }) {
  return postJSON({
    url: `/api/v2/${productId}/posts/batch`,
    data: {
      ids,
      opt,
      val,
      force_delete: forceDelete,
    },
  });
}
/**
 * 批量更新评论状态
 * @param {{productId: string, ids: array, opt: string, val: string, forceDelete: boolean}}
 * @param {string} postId 帖子 id
 */
export function postApiV2RepliesBatch({ productId, ids, opt, val, forceDelete }) {
  return postJSON({
    url: `/api/v2/${productId}/replies/batch`,
    data: {
      ids,
      opt,
      val,
      force_delete: forceDelete,
    },
  });
}

/**
 * 帖子操作
 * @deprecated 对帖子的操作，改用 post_api_v2_posts 接口
 * @param {string} productId 产品 id
 * @param {string} postId 帖子 id
 * @param {string} opt top 置顶
 * @param {string} val on 开关
 */
export function post_api_posts({ productId, postId, opt, val }) {
  return postJSON({
    url: `/api/v1/${productId}/posts/${postId}`,
    data: { opt, val },
  });
}

/**
 * 帖子操作
 * @param productId
 * @param postId
 * @param {string} opt      <top|lock|good|todo|hide|block>
 * @param {string} val      <on|off>
 * @param {string} scene    <post|dashboard|default>
 */
export function post_api_v2_posts({ productId, postId, opt, val, scene }) {
  return postJSON({
    url: `/api/v2/${productId}/posts/${postId}?scene=${scene}`,
    data: { opt, val },
  });
}

/**
 * 帖子点赞
 * @param productId
 * @param postId
 * @param {string}  val <on|off>    点赞或取消点赞
 */
export function post_api_posts_liked({ productId, postId, val }) {
  return postJSON({
    url: `/api/v2/${productId}/posts/${postId}/liked`,
    data: { val },
  });
}

/**
 * 删除帖子
 */
export function delete_api_posts({ productId, postId }) {
  return deleteJson({
    url: `/api/v2/${productId}/posts/${postId}`,
  });
}

/**
 * 对某个评论进行点赞
 * @deprecated 点赞改用 post_api_posts_replies_liked 接口，加黑改用 post_api_v2_posts_replies 接口
 * @param {string} productId 产品 id
 * @param {string} replyId 评论 id
 * @param {string} opt <like|unlike|hide|show|mark_as_spam|undo_mark_as_spam>   操作
 */
export function post_api_replies({ productId, replyId, opt }) {
  return postJSON({
    url: `/api/v1/${productId}/replies/${replyId}`,
    data: { opt },
  });
}

/**
 * 对某个评论进行操作
 * @param productId         产品id
 * @param postId            帖子id
 * @param replyId           评论id
 * @param opt   <block>     操作类型
 * @param val   <on|off>    开启或关闭
 */
export function post_api_v2_posts_replies({ productId, postId, replyId, opt, val }) {
  return postJSON({
    url: `/api/v2/${productId}/posts/${postId}/replies/${replyId}`,
    data: { opt, val },
  });
}

/**
 * 对某个评论进行点赞
 * @param productId         产品id
 * @param postId            帖子id
 * @param replyId           评论id
 * @param val   <on|off>    点赞或取消点赞
 */
export function post_api_posts_replies_liked({ productId, postId, replyId, val }) {
  return postJSON({
    url: `/api/v2/${productId}/posts/${postId}/replies/${replyId}/liked`,
    data: { val },
  });
}

/**
 * 删除评论
 * @param {string} productId    产品ID
 * @param {string} postId       主帖ID
 * @param {string} replyId      回复ID
 */
export function delete_api_posts_replies({ productId, postId, replyId }) {
  return deleteJson({
    url: `/api/v2/${productId}/posts/${postId}/replies/${replyId}`,
  });
}

/**
 * 获取打赏信息接口
 * @param {string} productId 产品 id
 * @param {string} postId 帖子 id
 */
export function get_api_tip({ productId, postId }) {
  return getJson({
    url: `/api/v1/${productId}/tip/${postId}`,
  });
}

/**
 * 打赏接口,进行打赏
 * @param {string} productId    产品 id
 * @param {string} postId       帖子 id
 * @param {number} amount       打赏金额，单位是分
 * @param {string} [endpoint]   平台类型，如果是 PC 需要传 PC
 */
export function post_api_tip({ productId, postId, amount, endpoint }) {
  return postJSON({
    url: `/api/v1/${productId}/tip/${postId}`,
    data: {
      amount,
      endpoint,
    },
  });
}

/**
 * 确认微信支付是否成功
 * @param productId     产品ID
 * @param tipId         支付订单
 */
export function post_api_tip_callback({ productId, tipId }) {
  return postJSON({
    url: `/api/v1/${productId}/tip/callback/${tipId}`,
  });
}

/**
 * 获得帖子
 * @deprecated 改用v3接口 getApiV3PostsReplies
 * @param {string} productId        产品 id
 * @param {string} postId           帖子 id
 * @param {string} [next_page_id]   分页id
 */
export function get_api_posts_replies({ productId, postId, next_page_id }) {
  return getJson({
    url: `/api/v2/${productId}/posts/${postId}/replies`,
    data: {
      next_page_id,
    },
  });
}

/**
 * 回复帖子
 * @param {string} productId            产品ID
 * @param {string} postId               主帖ID
 * @param {string} text                 回复文本
 * @param {array} [categories]          分类
 * @param {array} [images]              上传附件
 * @param {string} [parent_reply_id]    回复的引用 ID
 * @param {array} [user_contacts]       用户分类
 * @param {boolean} [disable_hide]      显示隐藏的帖子
 */
export function post_api_posts_replies({
  productId,
  postId,
  text,
  parent_reply_id = null,
  categories = [],
  images = [],
  user_contacts = [],
  disable_hide,
  captcha_randstr,
  captcha_ticket,
}) {
  return postJSON({
    url: `/api/v1/${productId}/posts/${postId}/replies`,
    data: {
      categories,
      image_upload_type: 'file',
      images,
      parent_reply_id,
      text,
      user_contacts,
      disable_hide,
      captcha_randstr,
      captcha_ticket,
    },
  });
}

/**
 * 赞赏人和次数的获取接口
 * @param {string} productId 产品 id
 * @param {string} postId 帖子 id
 */
export function post_api_tip_batch({ productId, postId }) {
  return postJSON({
    url: `/api/v1/${productId}/tip/batch`,
    data: {
      post_id_list: [postId],
    },
  });
}

/**
 * 上传图片
 */
export function post_api_posts_upload_images({ productId, data, onProgress }) {
  if (data && typeof data.get === 'function') {
    const type = data.get('type');
    const saveType = ['post', 'reply', 'team_blog', 'i_want_it', 'i want_reply'];
    if (!saveType.includes(type)) {
      new Collect({ ea: 'UNKNOWN_IMAGE_TYPE', ev: type }).report();
    }
  }
  return postFormData({
    url: `/api/v1/${productId}/posts/upload/images`,
    data,
    onProgress,
  });
}

/**
 * 帖子详情页的访问人信息
 * @deprecated 改用通用的 get_api_user_info
 * @param {string} productId 产品 id
 */
export function get_api_mobile_info({ productId }) {
  return getJson({
    url: `/api/v1/${productId}/mobile/info`,
  });
}

/**
 * 举报
 * @param {string} productId    产品 id
 * @param {string} object_id    帖子 id
 * @param {string} comment      举报类型 [ad|sex|abuse|illegal|political|other]
 * @param {string} object_type  举报对象类型，post 主帖，reply 评论
 */
export function post_api_abuse_reports({ productId, object_id, comment, object_type }) {
  return postJSON({
    url: `/api/v1/${productId}/abuse_reports`,
    data: {
      comment,
      object_id,
      object_type,
    },
  });
}

/**
 * 获取贴子和评论的点赞情况
 * @param {string} productId 产品 id
 * @param {array} object_list id 数组
 */
export function post_api_liked({ productId, object_list }) {
  return postJSON({
    url: `/api/v2/${productId}/liked`,
    data: {
      object_list,
    },
  });
}

/**
 * 游客态登录
 * @param {string|number} productId
 * @returns
 */
export function getApiSystemLogin(productId) {
  return getJson({
    url: `/login/system/${productId}`,
  });
}

/**
 * 授权某个 token 用户进行登录
 * @param login_token
 */
export function post_api_login({ login_token }) {
  return postJSON({
    url: '/api/v1/auth/login',
    data: { login_token },
  });
}

/**
 * 检查某个登录 token 是否被授权登录
 * @param login_token
 */
export function get_api_auth_login({ login_token }) {
  return getJson({
    url: '/api/v1/auth/login',
    data: { login_token },
  });
}

/**
 * 创建授权登录的token
 */
export function post_api_auth_login_token_create() {
  return postJSON({ url: '/api/v1/auth/login/token/create' });
}

/**
 * 管理员热力图
 * @param {string} productId    产品 id
 * @param {string} days         天数
 * @param {string} [sig]        签名
 */
export function post_api_admin_heat({ productId, days, sig }) {
  return postJSON({
    url: `/api/v1/${productId}/admin/data/heat`,
    data: {
      days,
      sig: sig || undefined,
    },
  });
}

/**
 * 管理员回复时间序列图
 * @param {string} productId    产品 id
 * @param {string} days         天数
 * @param {string} [sig]        签名
 */
export function post_api_admin_reply({ productId, days, sig }) {
  return postJSON({
    url: `/api/v1/${productId}/admin/data/reply`,
    data: {
      days,
      sig: sig || undefined,
    },
  });
}

/**
 * 历史荣誉榜
 * @param productId
 * @param sig
 */
export function post_api_admin_data_running_reply({ productId, sig }) {
  return postJSON({
    url: `/api/v1/${productId}/admin/data/running/reply`,
    data: {
      sig: sig || undefined,
    },
  });
}

/**
 * 管理员点赞统计
 * @param {string} productId    产品 id
 * @param {string} days         天数
 * @param {string} [sig]        签名
 */
export function post_api_admin_like({ productId, days, sig }) {
  return postJSON({
    url: `/api/v1/${productId}/admin/data/like`,
    data: {
      days,
      sig: sig || undefined,
    },
  });
}

/**
 * 创建产品
 * @param {string} productName      产品名
 * @param {string} type <mobile>    产品类型
 * @param {string} captcha_ticket  腾讯云验证码校验
 * @param {string} captcha_randstr 腾讯云验证码校验
 * @param {number} from            创建来源
 */
export function post_api_products({ productName, type, captcha_ticket, captcha_randstr, from }) {
  return postJSON({
    url: '/api/v1/products',
    data: {
      title: productName,
      type,
      captcha_ticket,
      captcha_randstr,
      from,
    },
  });
}

/**
 * 判断是否登录 qq 账号
 */
export function geApiMobileCheckQQLogin() {
  return getJson({ url: '/api/v1/mobile/check_qq_login' });
}



export function postApiV2Logout() {
  return postJSON({ url: '/api/v2/logout' });
}
/**
 * 关联 oa 账号接口
 */
export function post_api_secret_check() {
  return postJSON({
    url: '/api/v1/secret/check',
  });
}

/**
 * 创建博客
 * @param {number} productId     产品ID
 * @param {string} content       博客富文本内容
 * @param {string} cover_img     博客封面
 * @param {string} status <draft|publish>       博客状态
 * @param {string} title
 * @returns {*}
 */
export function post_api_teamblog({
  productId,
  content,
  cover_img,
  status = 'draft',
  title,
  captcha_randstr,
  captcha_ticket,
}) {
  return postJSON({
    url: `/api/v1/${productId}/teamblog`,
    data: { content, cover_img, status, title, captcha_randstr, captcha_ticket },
  });
}

/**
 * 获取博客内容
 * @param productId 产品ID
 * @param blogId    博客ID
 */
export function get_api_teamblog({ productId, blogId }) {
  return getJson({
    url: `/api/v1/${productId}/teamblog/${blogId}`,
  });
}

/**
 * 更新指定博客内容
 * @param {number} productId                    产品ID
 * @param {number} blogId                       博客ID
 * @param {string} content                      博客富文本内容
 * @param {string} cover_img                    博客封面
 * @param {string} status <draft|publish>       博客状态
 * @param {string} title
 */
export function update_api_teamblog({ productId, blogId, content, cover_img, status = 'draft', title }) {
  return postJSON({
    url: `/api/v1/${productId}/teamblog/${blogId}`,
    data: { content, cover_img, status, title },
  });
}

/**
 * 删除指定博客
 * @param productId 产品ID
 * @param blogId    博客ID
 */
export function post_api_teamblog_delete({ productId, blogId }) {
  return postJSON({
    url: `/api/v1/${productId}/teamblog/${blogId}/delete`,
  });
}

/**
 * 获取指定博客的点赞数据
 * @param {number}  productId   产品ID
 * @param {array}   ids         博客ID
 */
export function post_api_v2_teamblog_liked({ productId, ids }) {
  return postJSON({
    url: `/api/v2/${productId}/teamblog/liked`,
    data: {
      object_list: ids,
    },
  });
}

/**
 * 上报团队博客的阅读
 * @param productId 产品ID
 * @param blogId    博客ID
 */
export function get_api_teamblog_read({ productId, blogId }) {
  return getJson({
    url: `/api/v1/${productId}/teamblog/${blogId}/read`,
  });
}

/**
 * 点赞指定的博客
 * @param productId 产品ID
 * @param blogId    博客ID
 */
export function post_api_v2_teamblog_like({ productId, blogId }) {
  return postJSON({
    url: `/api/v2/${productId}/teamblog/like`,
    data: {
      id: blogId,
    },
  });
}

/**
 * 取消点赞指定的博客
 * @param productId
 * @param blogId
 */
export function post_api_v2_teamblog_unlike({ productId, blogId }) {
  return postJSON({
    url: `/api/v2/${productId}/teamblog/unlike`,
    data: {
      id: blogId,
    },
  });
}

/**
 * 管理员获取团队博客列表
 * @param productId
 * @param page
 * @param pageSize
 */
export function post_api_teamblog_dashboard_list({ productId, page = 1, pageSize = 10 }) {
  return postJSON({
    url: `/api/v1/${productId}/teamblog/dashboard/list`,
    data: { page, pageSize },
  });
}

/**
 * 获取团队博客列表
 * @param productId
 * @param page
 * @param pageSize
 */
export function post_api_teamblog_list({ productId, page = 1, pageSize = 10 }) {
  return postJSON({
    url: `/api/v1/${productId}/teamblog/list`,
    data: { page, pageSize },
  });
}

/**
 * 获取荣誉榜用户排行
 * @param date (Number) 日期
 * @param group (str) 分组
 *
 */
export function get_api_ranking_user({ date, group }) {
  return getJson({
    url: '/api/v1/ranking/user',
    data: { date, group },
  });
}

/**
 * 获取荣誉榜产品排行
 * @param date (Number) 日期
 *
 */
export function get_api_ranking_product({ date }) {
  return getJson({
    url: '/api/v1/ranking/product',
    data: { date },
  });
}

/**
 * 获取荣誉榜优秀回复
 * @param date (Number) 日期
 *
 */
export function get_api_ranking_reply({ date }) {
  return getJson({
    url: '/api/v1/ranking/reply',
    data: { date },
  });
}

/**
 * 荣誉墙浏览
 * @param type
 *
 */
export function post_api_honor_board_read({ type }) {
  return postJSON({
    url: '/api/v1/hornor_board/read',
    data: { type },
  });
}

/**
 * 荣誉墙点赞
 * @param userId
 *
 */
export function post_api_honor_board_like({ userId }) {
  return postJSON({
    url: `/api/v2/hornor_board/${userId}/like`,
  });
}

/**
 * 荣誉墙取消点赞
 * @param userId
 *
 */
export function post_api_honor_board_unlike({ userId }) {
  return postJSON({
    url: `/api/v2/hornor_board/${userId}/unlike`,
  });
}

/**
 * 荣誉墙点赞信息
 * @param {array} object_list
 *
 */
export function post_api_honor_board_like_stat({ object_list }) {
  return postJSON({
    url: '/api/v2/hornor_board/like/stat',
    data: { object_list },
  });
}

/**
 * 获取某个产品下面的用户信息
 * @param productId
 */
export function get_api_user_info({ productId }) {
  return getJson({ url: `/api/v1/${productId}/user/info` }).then((res) => {
    if (res && res.data) {
      // 在获取用户信息后统一设置埋点数据
      horizon.setUserId(res.data.user_id);
      aegis.setUserId(res.data.user_id);
    }

    return res;
  });
}

/**
 * 获取通用的用户信息
 */
export function get_api_account() {
  return getJson({ url: '/api/v1/account' });
}

/**
 * 获取普通产品用户信息
 */
export function get_api_productUser(productId) {
  return getJson({ url: `/api/v1/${productId}/mobile/info` });
}

/**
 * 大产品首页，常见问题列表， 关键字： faq
 * @param {string} productId  产品ID
 * @param {string} status 获取的问题类型，默认值 published
 */
export function get_api_faqs({ productId, status = 'published', prev_page_id, next_page_id }) {
  return getJson({
    url: `/api/v2/${productId}/faqs`,
    data: {
      status,
      prev_page_id,
      next_page_id,
    },
  });
}
/**
 * 常见问题详情 关键字：faqs
 */
export function get_api_faqs_details({ productId, postId }) {
  return getJson({ url: `/api/v2/${productId}/faqs/${postId}` });
}
/**
 * 常见问题排榜 关键字: faqs  read_total
 * @param {string} productId 产品ID
 * @param {string} faqNum 获取问题的数量
 */
export function get_api_faqs_read_total({ productId, faqNum }) {
  return getJson({ url: `/api/v1/${productId}/faqs/${faqNum}/top/read_total` });
}

/**
 * 大产品首页帖子列表接口 关键字：posts static
 * @deprecated
 * @param {string} productId 产品ID
 */
export function get_api_posts_static({ productId }) {
  return getJson({ url: `/api/v1/${productId}/posts/static` });
}

/**
 * 威尔逊算法的帖子列表
 * @param productId
 */
export function get_api_v1_posts_wilson({ productId }) {
  return getJson({ url: `/api/v1/${productId}/posts/wilson` });
}

/**
 * 拉取静态帖子列表，
 * 被缓存的数据，每个人看到都一样的内容
 * @param productId
 */
export function get_api_v2_posts_static({ productId }) {
  return getJson({ url: `/api/v2/${productId}/posts/static` });
}

/**
 * 威尔逊算法的帖子列表
 * @param productId
 */
export function get_api_v2_posts_wilson({ productId, page }) {
  return getJson({
    url: `/api/v2/${productId}/posts/wilson`,
    data: { page },
  });
}

/**
 * 动态数据，普通用户拉取自己的帖子
 * @param productId
 * @param [prev_page_id]
 * @param [next_page_id]
 * @param [per_page]
 */
export function get_api_v2_posts_user({ productId, prev_page_id, next_page_id, per_page }) {
  return getJson({
    url: `/api/v2/${productId}/posts/user`,
    data: { prev_page_id, next_page_id, per_page },
  });
}

/**
 * 动态数据，管理员拉取全部帖子
 * @param productId
 * @param [prev_page_id]
 * @param [next_page_id]
 * @param [per_page]
 */
export function get_api_v2_posts_admin({ productId, prev_page_id, next_page_id, per_page }) {
  return getJson({
    url: `/api/v2/${productId}/posts/admin`,
    data: { prev_page_id, next_page_id, per_page },
  });
}

/**
 * 获取帖子列表中帖子的点赞数和用户是否已点赞的状态
 * @param productId
 * @param ids
 */
export function post_api_v2_posts_liked({ productId, ids = [] }) {
  return postJSON({
    url: `/api/v2/${productId}/posts/liked`,
    data: { ids },
  });
}

/**
 * 设置移动端首页展示方式 showType
 * @param productId 产品ID
 * @param {number} display_mode 1 表示大产品模式  0 表示普通产品模式
 */
export function post_api_mobile_home_show_type({ productId, display_mode }) {
  return postJSON({
    url: `/api/v1/${productId}/products/display`,
    data: { display_mode },
  });
}

/**
 * 帖子列表 - 获取动态帖子
 * @deprecated 改用 get_api_v2_posts_admin 和 get_api_v2_posts_user
 * @param {string} productId
 * @param {string} post_user_id  用户userId, 获取用户发的帖子
 * @param {string} page  分页页数，管理员帖子列表分页
 */
export function get_api_posts_list({ productId, post_user_id, page }) {
  return getJson({
    url: `/api/v1/${productId}/posts`,
    data: { post_user_id, page },
  });
}

/**
 * 用研云中台-吐槽模块
 */
export function getApiAloudTucao() {
  return getJson({ url: '/api/v1/reports/ucloud' });
}

/**
 * 获取当前产品的用户信息收集列表
 * @param productId
 */
export function get_api_user_contact({ productId }) {
  return getJson({
    url: `/api/v1/${productId}/user_contact`,
  });
}

/**
 * 保存当前产品的用户信息收集列表
 * @param productId
 * @param {array} [added]       新增或者编辑的数据集合，有 id 是编辑，无 id 是新增
 * @param {array} [deleted]     需要被删除的数据集合
 */
export function post_api_user_contact({ productId, data = {} }) {
  return postJSON({
    url: `/api/v1/${productId}/user_contact`,
    data,
  });
}

/**
 * 预览当前产品的用户信息收集列表
 * @param productId
 * @param {array} [added]       新增或者编辑的数据集合，有 id 是编辑，无 id 是新增
 * @param {array} [deleted]     需要被删除的数据集合
 */
export function post_api_user_contact_preview({ productId, added = [], deleted = [] }) {
  return postJSON({
    url: `/api/v1/${productId}/user_contact/preview`,
    data: { added, deleted },
  });
}

/**
 * 产品设置
 * @param productId
 * @param type          设置的字段
 * @param data          设置的字段的值
 */
export function post_api_products_attribute({ productId, type, data }) {
  return postJSON({
    url: `/api/v1/${productId}/products/attribute`,
    data: {
      type,
      data,
    },
  });
}

/**
 * 产品菜单展示
 * @获取大小产品底部导航栏信息
 */
export function get_api_products_menu(productId) {
  return getJson({ url: `/api/v1/${productId}/menu` });
}

/**
 * 产品设置封面头图
 * @param productId
 * @param formData        上传的 header_image_pc 和 header_image_mobile
 */
export function post_api_products_banner({ productId, formData }) {
  return postFormData({
    url: `/api/v1/${productId}/products/header_image_config`,
    data: formData,
  });
}

/**
 * 产品设置封面头图恢复默认
 * @param productId
 */
export function post_api_products_banner_setDefault(productId) {
  return getJson({ url: `/api/v1/${productId}/products/header_image_reset` });
}

/**
 * 移动端用户取消微信通知
 * @param {string} productId
 * @param {string} action
 */
export function get_api_weixin_push_action_setting(productId, action = 'cancel') {
  const qs = [`action=${action}`];
  return getJson({ url: `/api/v1/${productId}/weixin_push/action_setting?${qs.join('&')}` });
}

/**
 * 移动端用户开通微信通知
 * @param {string} productId
 * @param {string} postId
 * @param {string} userId
 */
export function get_api_weixin_auth_create({ productId, postId, userId }) {
  const qs = [`product_id=${productId}`, `f_title_id=${postId}`, `user_id=${userId}`];
  return getJson({ url: `/api/v1/weixin_auth_create?${qs.join('&')}` });
}

/**
 * 获取产品信息-公开
 * @param productId
 */
export function get_api_products_info(productId) {
  return getJson({ url: `/api/v1/${productId}/products/info` });
}

/**
 * 获取产品信息-非公开，给管理员使用
 * @param productId
 */
export function get_api_v2_products(productId) {
  return getJson({ url: `/api/v2/${productId}/products` });
}

/**
 * 获取发帖弹窗的内容
 * @param {string|number} productId
 */
export function get_api_post_popup_content(productId) {
  return getJson({ url: `/api/v1/${productId}/settings` });
}

/**
 * PC发帖
 *  {string} productId
 *  {string} text 帖子内容
 *  {array} images 上传的图片
 *  {Array<Object>} user_contacts 用户信息如：微信 QQ ，是否接受产品联系
 *  {string} image_upload_type 文件类型 :file
 */
export function post_api_products_posts({
  productId,
  text,
  images,
  user_contacts,
  categories = [],
  image_upload_type = 'file',
  topicId,
  topicAnswer,
  ...restParams
}) {
  return postJSON({
    url: `/api/v1/${productId}/posts`,
    data: {
      text,
      images,
      user_contacts,
      categories,
      image_upload_type,
      topic_id: topicId,
      topic_answer: topicAnswer,
      ...restParams,
    },
  });
}

/**
 * 根据评论ID集合，获取点赞相关数据 PC产品首页获取帖子评论点赞
 * @param {string} productId
 * @param {arr} ids 帖子ID集合
 */
export function post_api_post_reply_liked({ productId, ids }) {
  return postJSON({
    url: `/api/v2/${productId}/replies/liked`,
    data: { ids },
  });
}

/**
 * 微信扫码授权
 *
 */
export function get_api_wx_subscribe({ productId, postId }) {
  return getJson({ url: `/api/v1/${productId}/posts/${postId}/subscribe/status` });
}

/**
 * 赞赏人和次数的获取接口
 * @param {string} productId 产品 id
 * @param {Array<string>} postIdArr 帖子 id数组
 */
export function post_api_tip_batch_list({ productId, postIdList }) {
  return postJSON({
    url: `/api/v1/${productId}/tip/batch`,
    data: {
      post_id_list: postIdList,
    },
  });
}

/**
 * 批量获取各贴打赏信息
 * @param {string} productId
 * @param {Array<string>} postIdList 帖子id数组
 */
export function post_api_tip_batch_info({ productId, postIdList }) {
  return postJSON({
    url: `/api/v1/${productId}/tip/batch_record`,
    data: { post_id_list: postIdList },
  });
}

// 预览反馈分类和用户信息收集
export function get_api_startup_preview({ productId, status = 'preview' }) {
  return getJson({
    url: `/api/v1/${productId}/settings`,
    data: { status },
  });
}

// 邀请管理员-获取邀请人信息
export function get_api_inviter_information({ create_admin_str }) {
  return getJson({
    url: 'api/v1/administrators/check_inviter_info',
    data: { create_admin_str },
  });
}

/**
 * 获取各标签总数
 * @param {string} productId
 */
export function get_api_labels_overview({ productId, readInfo }) {
  return getJson({ url: `/api/v1/${productId}/labels/overview`, data: { read_info: readInfo } });
}

/**
 * 新增 GET api/v2/:product_id/posts/label/:label
 * 产品帖子列表。根据当前登陆用户身份展示
 * @deprecated 废弃，改用 get_api_v2_posts_label_show\get_api_v2_posts_label_hide\get_api_v2_posts_label_good 替代
 * @param {string} productId
 * @param {string} label show | good | hide | reply | report
 */
export function get_api_posts_label_list({ productId, label, next_page_id }) {
  return getJson({
    url: `/api/v2/${productId}/posts/label/${label}`,
    data: { next_page_id },
  });
}

/**
 * 获取最新帖子列表
 * @param productId
 * @param next_page_id
 */
export function get_api_v2_posts_label_show({ productId, next_page_id }) {
  return getJson({
    url: `/api/v2/${productId}/posts/label/show`,
    data: { next_page_id },
  });
}

/**
 * 获取隐藏帖子，只有管理员可以获取
 * @param productId
 * @param next_page_id
 */
export function get_api_v2_posts_label_hidden({ productId, next_page_id, readInfo }) {
  return getJson({
    url: `/api/v2/${productId}/posts/label/hidden`,
    data: { next_page_id, read_info: readInfo },
  });
}

/**
 * 获取好问题列表
 * @param productId
 * @param next_page_id
 */
export function get_api_v2_posts_label_good({ productId, next_page_id }) {
  return getJson({
    url: `/api/v2/${productId}/posts/label/good`,
    data: { next_page_id },
  });
}

/**
 * 邀请管理员加入团队跳转
 */
export function api_location_dashboard(create_admin_str) {
  return `/api/v1/administrators/create_administrator?create_admin_str=${create_admin_str}`;
}

/**
 * 获取当前用户有权限的产品
 */
export function get_api_products(data) {
  return getJson({
    url: '/api/v1/products',
    data,
  });
}

/*
 * 管理后台旧工程迁移过来的创建产品
 */
export function post_api_pc_createProducts(data) {
  return postJSON({
    url: '/api/v1/products',
    data,
  });
}

/**
 * 获取（重置）邀请管理员链接
 * @param {integer} productId
 * @param 重置链接 data 需要传 { reset: true }
 */
export function getApiGetOrResetVerifyUrl(productId, data) {
  return getJson({
    url: `/api/v1/${productId}/administrators/get_verify_url`,
    data,
  });
}

/** *
 * 埋点收集 event 数据
 */
export function get_api_collect() {
  return '/api/v1/collect';
}


/**
 * 常见问题 - 问题分类 - 获取第一级分类列表
 */
export function get_api_faqs_categories_list({ productId }) {
  return getJson({ url: `/api/v2/${productId}/faqs/categories_list` });
}

/**
 * 常见问题 - 分类 - 获取指定分类下的列表
 */

export function get_api_faqs_categories_list_categoryId({ productId, categoryId, categories_only = 0, scene = '' }) {
  return getJson({
    url: `/api/v2/${productId}/faqs/categories_list/${categoryId}`,
    data: {
      categories_only,
      scene,
    },
  });
}

/**
 * 常见问题-单个-预览
 * @param {string} faqIdPreview
 */
export function get_api_faqs_preview({ productId, faqIdPreview }) {
  return getJson({ url: `/api/v2/${productId}/faqs/preview/${faqIdPreview}` });
}

/**
 * 问题详情反馈 此问题是否有帮助
 * @param {{ productId: string|number, faqId: number, isSolved: string }}
 */
export function post_api_faqs_solved({ productId, faqId, isSolved }) {
  return postJSON({ url: `/api/v1/${productId}/faqs/${faqId}/${isSolved}` });
}

/**
 * 常见问题-分类查看
 */
export function get_api_faqs_categories({ productId, faqId }) {
  return getJson({ url: `/api/v2/${productId}/faqs/${faqId}/categories` });
}

/**
 * 问题详情-上报阅读数量
 */
export function post_api_faqs_add_read_total({ productId, faqId }) {
  return postJSON({ url: `/api/v1/${productId}/faqs/${faqId}/add/read_total` });
}

/**
 * 查询用户是否在24小时内，点击过取消微信通知
 */
export function get_api_weixin_push_hascancle({ productId }) {
  return getJson({ url: `/api/v1/${productId}/weixin_push/hascancle` });
}

/**
 * 获取微信通知的微信确认地址
 */
export function getWeixinPushWXlinkPush({ productId }) {
  return getJson({ url: `/api/v1/${productId}/weixin_push/wxlink_push` });
}

/**
 * 获取cookie，目前只有小程序需要，web端是后端种cookie
 */
export function get_weapp_cookie({ productId, code, signature, rawData, encryptedData, iv }) {
  return postJSON({
    url: `/api/v1/${productId}/weapp/get/cookie`,
    data: {
      code,
      signature,
      rawData,
      encryptedData,
      iv,
    },
  });
}


/**
 * 授权-平台_应用-获取产品列表（授权标识）
 * @returns {Promise<{ data: object, pagination: any }>}
 */
export function getApiAuthorizeProductList({ platformId, appId, data = {} }) {
  return getJson({ url: `/api/v1/product/authorize/${platformId}/${appId}`, data });
}

/**
 * 获取产品可关联的平台应用
 */
export function get_api_authorize_platform_list({ productId }) {
  return getJson({ url: `/api/v1/${productId}/platform` });
}

/**
 * 授权页面展示对应平台信息
 */
export function getApiAuthorizeAlatformInfo({ platformId }) {
  return getJson({ url: `/api/v1/platform/${platformId}` });
}

/**
 * TAPD项目授权回调-code
 */
export function get_api_tapd_oauth_callback({ code, state, resource }) {
  return getJson({
    url: '/tapd/oauth/callback/code',
    data: {
      code,
      state,
      resource,
    },
  });
}

/**
 * 获取接入平台的信息
 */
export function getApiPlatfromInfo({ productId, platformId }) {
  return getJson({ url: `/api/v1/${productId}/platform/info/${platformId}` });
}

/**
 * 授权页面，用户选择产品同意授权
 *
 * @param platformId 接入平台_ID
 * @param appId 接入平台_应用_ID
 * @param appName 接入平台_应用_名称
 * @param scope 权限范围
 * @param redirectUri 授权后重定向的回调链接地址
 * @param state
 * @param productIds 产品ID集合,json
 * @param status 用户授权状态，默认为false
 */
export function postApiAuthorizeAgree({ product_ids, status }) {
  return postJSON({
    url: `/platform/oauth2/authorize${location.search}`,
    data: { product_ids, status },
  });
}

/**
 * 授权回调事件
 *
 * @param productId 产品ID
 * @param txc_app_id 兔小巢_应用ID
 * @param txc_post_id 兔小巢_帖子ID
 * @param txc_test 测试环境mock数据
 * @param item_id 接入平台_应用_事项_ID
 * @param event 事件类型：{
 *     app_item_list_show: 接入平台_应用_事项-已关联 - 获取列表
 *     app_item_list_show_for_search: 接入平台_应用_事项-待关联 - 获取列表
 *     app_item_bind：接入平台_应用_事项 关联 兔小巢_产品_帖子
 *     app_item_unbind: 接入平台_应用_事项 取消关联 兔小巢_产品_帖子
 * }
 */
export function post_api_authorize_callback({ productId, ...parameters }) {
  const txc_test = parseInt(getUrlParam('txc_test'), 10) || undefined;
  return postJSON({
    url: `/event/product/${productId}`,
    data: { ...parameters, txc_test },
  });
}


/**
 * 产品的订阅配置-获取
 */
export function getApiNotificationSetting({ productId }) {
  return getJson({ url: `/api/v2/${productId}/notification/setting` });
}

/**
 * 产品的订阅配置-更新
 */
export function postApiNotificationSetting({ productId, data }) {
  const params = { ...data };
  // 这里有个坑，如果全关不能全传空字符串，需要传空对象，这里后端不设计缺陷，只能前端处理了
  if (typeof params.method === 'object') {
    if (Object.values(data.method).every(value => value === '')) {
      params.method = {};
    }
  }
  return postJSON({ url: `/api/v2/${productId}/notification/setting`, data: params });
}

/**
 * 用户的订阅配置-获取
 */
export function geApiNotificationUserSetting({ productId, channel }) {
  return getJson({
    url: `/api/v2/${productId}/notification/user/setting`,
    data: {
      channel,
    },
  });
}

/**
 * 订阅记录-更新 - 微信公众号-订阅通知
 */
export function postApiAotificationAubscribeWX({ productId, scene, type, id }) {
  return postJSON({
    url: `/api/v2/${productId}/notification/subscribe/wx`,
    data: {
      scene,
      object_type: type,
      object_id: id,
    },
  });
}

/**
 * 订阅记录-更新 - 微信小程序-订阅通知
 */
export function postApiNotificationSubscribeWXMini({ productId, scene, type, id }) {
  return postJSON({
    url: `/api/v2/${productId}/notification/subscribe/wx_mini`,
    data: {
      scene,
      object_type: type,
      object_id: id,
    },
  });
}

/**
 * 订阅记录-更新 （暂不启用，后续可能变更）
 */
export function postApiNotificationSubscribe({ productId, user_id, scene, type, id, channel }) {
  return postJSON({
    url: `/api/v2/${productId}/notification/subscribe`,
    data: {
      product_id: productId,
      user_id,
      scene,
      object_type: type,
      object_id: id,
      channel,
    },
  });
}
/**
 * 微信JSAPI签名信息-获取
 */
export function get_api_weixin_jsapi_signature({ url }) {
  return getJson({
    url: '/api/v1/weixin/jsapi/signature',
    data: {
      url,
    },
  });
}

/**
 * 用户的订阅配置-更新
 */
export function postApiNotificationUserSetting({ productId, channel, status }) {
  return postJSON({
    url: `/api/v2/${productId}/notification/user/setting`,
    data: {
      channel,
      status,
    },
  });
}

/**
 * 用户的订阅配置-更新
 */
export function postApiNotificationSubscribeWXTicket({ productId, scene, type, id, ticket }) {
  return postJSON({
    url: `/api/v2/${productId}/notification/subscribe/wx/ticket`,
    data: {
      scene,
      status,
      object_type: type,
      object_id: id,
      ticket,
    },
  });
}

/**
 * 用户的订阅配置-渠道配置 - 发送验证
 */
export function postApiNotificationUserVerifySend({ productId, user_id, channel, channel_uid, type }) {
  return postJSON({
    url: `/api/v2/${productId}/notification/user/verify/send`,
    data: {
      product_id: productId,
      user_id,
      channel,
      channel_uid,
      type,
    },
  });
}

/**
 * 用户的订阅配置-渠道配置 - 验证CODE
 */
export function postApiNotificationUserVerifyCode({ productId, user_id, code, channel, channel_uid }) {
  return postJSON({
    url: `/api/v2/${productId}/notification/user/verify/code`,
    data: {
      product_id: productId,
      user_id,
      code,
      channel,
      channel_uid,
    },
  });
}


/**
 * 企业版 - 热门话题
 *
 * @param cid 同上
 */
export function getApiEnterpriseTopics({ cid, accessType }) {
  return getJson({
    url: `/api/v2/${cid}/enterprise/topics`,
    data: { access_type: accessType },
  });
}

/**
 * 企业版 - 热门（产品）排行
 *
 * @param cid 同上
 * @param access_type 同上
 */
export function get_api_enterprise_hot_products({ cid, access_type }) {
  return getJson({
    url: `/api/v2/${cid}/feedback_center/index/hot_products`,
    data: { access_type },
  });
}

/**
 * 企业版 - 最佳回复产品
 */
export function get_api_enterprise_best_product({ cid, access_type }) {
  return getJson({ url: `/api/v2/${cid}/feedback_center/index/best_product`, data: { access_type } });
}

/**
 * 企业版 - 企业员工活跃体验榜
 */
export function get_api_enterprise_activity_staff({ cid, access_type }) {
  return getJson({ url: `/api/v2/${cid}/feedback_center/index/activity_staff`, data: { access_type } });
}

/**
 * 订阅信息-获取（通知渠道）
 * @param {Number} productId 产品ID
 * @param {Object} data 参数
 */
export function getApiNotificationSubscribeInfo(productId, data) {
  return getJson({ url: `/api/v2/${productId}/notification/subscribe/info`, data });
}

/**
 * 轮询确认订阅状态
 */
export function getApiNotificationSubscribeStatus(productId, data) {
  return getJson({ url: `/api/v2/${productId}/notification/subscribe/status`, data });
}

/**
 * 获取公开产品类目列表
 */
export function getApiProductCategories() {
  return getJson({ url: '/api/v2/product/categories' });
}

/**
 * 检查重定向后的地址
 */
export function get_api_nce_id_map(cid, nce_id, type) {
  return getJson({ url: `/api/v2/${cid}/feedback_center/nce_id_map`, data: { nce_id, type } });
}

/*
 * 查询引导弹窗是否需要展示
 */
export function get_api_check_guide({ type }) {
  return getJson({ url: '/api/v2/guide_be/check', data: { type } });
}

/**
 * 上报展示过的引导弹窗
 */
export function post_api_guide_displayed(formData) {
  return postFormData({
    url: '/api/v2/guide_be/guided',
    data: formData,
  });
}



/** tapd start */

/**
 * tapd相关请求需要添加Authorization
 * @typedef {{ Authorization: string }} Authorization
 */

/*
* 通过tapd code 换取token
* tapd的接口不回返回data/code结构
* 用request的fail逻辑获取响应
*/
export function postTapdAccessToken({ code, app_id }) {
  return postJSON({ url: '/platform/tapd/access_token', data: { code, app_id } })
    .catch(({ response }) => response);
}

/**
 * 获取关联的兔小巢产品信息
 */
export function getPlatformProducts({ platformId, appId }) {
  return getJson({ url: '/platform/app/products', data: { platform_id: platformId, app_id: appId } });
}

/**
 * 获取所有兔小巢关联的项目
 * @param {object} params
 * @param {string} params.platform_id
 * @param {string} params.app_id
 * @param {Authorization} headers - 请求头
 * @returns
 */
export function getTapdPlatformProducts(params, headers) {
  return getJson({ url: '/openapi/platform/app/products', data: params, headers });
}

/**
 * 获取关联的兔小巢应用的帖子
 * @param {object} params
 * @param {string} params.platform_id - 平台 -ID
 * @param {string} params.app_id - 平台 应用 -ID
 * @param {string} params.txc_app_id - 兔小巢 应用 -ID
 * @param {string|undefined} [params.prev_page_id] - 从当前id 往前查找20条
 * @param {string|undefined} [params.next_page_id] - 从当前id 往后查找20条
 * @param {Authorization} headers - 请求头
 */
export function getTapdPlatformAppPosts(params, headers) {
  return getJson({ url: '/openapi/platform/app/posts', data: params, headers });
}

/**
 * 兔小巢反馈创建事项关联
 * @param {object} data
 * @param {string} data.platform_id - 平台 -ID
 * @param {string} data.app_id - 平台 应用 -ID
 * @param {string} data.txc_app_id - 兔小巢 应用 -ID
 * @param {string} data.txc_post_id - 兔小巢_应用-反馈_ID
 * @param {string} data.item_type - 平台_应用-事项_类型
 * @param {string} data.item_title - 平台_应用-事项_标题
 * @param {string} data.item_desc - 平台_应用-事项_描述
 * @param {string} [data.creator] - 平台_应用-事项_创建者
 * @param {string} [data.code] - 平台_应用-code 获取平台应用的用户信息，填充为空的creator字段
 * @param {Authorization} headers - 请求头
 */
export function postPlatformAppProject(data, headers) {
  return postJSON({ url: '/openapi/platform/app/item', data, headers });
}

/**
 * 获取tapd事项关联的兔小巢的帖子
 * @param {object} params
 * @param {string} params.platform_id - 平台 -ID
 * @param {string} params.app_id - 平台 应用 -ID
 * @param {string} params.app_item_type - 平台 应用 - 事项 类型
 * @param {string} params.app_item_id - 平台 应用 - 事项 类型
 * @param {string|undefined} [params.prev_page_id] - 从当前id 往前查找20条
 * @param {string|undefined} [params.next_page_id] - 从当前id 往后查找20条
 * @param {Authorization} headers - 请求头
 */
export function getTapdPlatformItemPosts(params, headers) {
  return getJson({ url: '/openapi/platform/app/item/posts', data: params, headers });
}


/**
 * 取消绑定兔小巢产品
 *
 * @param {number} productId
 * @param {string} txcAppId 兔小巢_产品_应用_ID
 */
export const apiPostAutorizedUnbind = (productId, txcAppId) => postJSON({ url: `/api/v1/${productId}/app/${txcAppId}/unbind` });

/**
 * 绑定兔小巢产品
 * @param {object} data
 * @param {string} data.platform_id - 接入平台_ID
 * @param {string} data.app_id - 接入平台_应用_ID
 * @param {string} data.code - 接入平台_应用—code验证项目信息合法
 * @param {string|number} data.product_id - 兔小巢_产品_ID
 * @returns
 */
export const apiPostAutorizedBind = data => postJSON({ url: '/api/v1/app/bind', data });

/**
 * 获取产品授权的平台应用
 *
 * @param productId
 */
export const apiGetAuthorizedApp = productId => getJson({ url: `/api/v1/${productId}/app` });
/** tapd end */

/**
 * 获取管理员昵称
 * @param {string} product_id      项目ID
 */
export function getApiAdministratorsPublic({ product_id }) {
  return getJson({
    url: `/api/v1/${product_id}/administrators/public`,
  });
}



/**
 * 喜欢一条 i want
 * @param productId
 */
export function postApiIwantLike({ productId, id }) {
  return postJSON({
    url: `/api/v2/${productId}/i_want_it/like`,
    data: {
      id,
    },
  });
}

/**
 * 取消喜欢一条 i want
 * @param productId
 */
export function postApiIwantUnlike({ productId, id }) {
  return postJSON({
    url: `/api/v2/${productId}/i_want_it/unlike`,
    data: {
      id,
    },
  });
}

/**
 * 对i want进行回复
 */
export function postApiIwantReplyCreate({ productId, wantId, parentId, images, text }) {
  return postJSON({
    url: `/api/v2/${productId}/i_want_it/reply/create`,
    data: {
      want_id: wantId,
      parent_id: parentId,
      images,
      text,
    },
  });
}

/**
 * 对i want进行回复
 */
export function getApiIwantReply({ productId, id, page = 1, maxId = null }) {
  return postJSON({
    url: `/api/v2/${productId}/i_want_it/replies`,
    data: {
      id,
      page,
      // max_id: maxId,
    },
  });
}

/**
 * 对i want 评论进行删除
 */
export function getApiIwantReplyDelete({ productId, id }) {
  return getJson({
    url: `/api/v2/${productId}/i_want_it/reply/delete`,
    data: {
      id,
    },
  });
}


/**
 * 对i want 评论进行点赞
 */
export function getApiIwantReplyLike({ productId, id }) {
  return postJSON({
    url: `/api/v2/${productId}/i_want_it/reply/like`,
    data: {
      id,
    },
  });
}

/**
 * 对i want 评论进行取消点赞
 */
export function getApiIwantReplyUnlike({ productId, id }) {
  return postJSON({
    url: `/api/v2/${productId}/i_want_it/reply/unlike`,
    data: {
      id,
    },
  });
}

/**
 * 对i want 评论进行取消点赞
 */
export function getApiIwantDetail({ productId, id }) {
  return getJson({
    url: `/api/v2/${productId}/i_want_it/detail`,
    data: {
      id,
    },
  });
}

/** tags start */

/**
 * 产品的标签-列表获取
 * @param {int} product_id
 * @param {string} content_type 内容类型。默认为 post
 * @param {string} scene 场景。默认为 default
 * @returns
 */
export function getProductsTagsList(product_id, scene = 'default') {
  return getJson({
    url: `/api/v2/${product_id}/tags`,
    data: {
      scene,
    },
  });
}

/**
 * 产品的标签-统计-列表获取
 * @param {int} product_id
 * @param {string} content_type 内容类型。默认为 post
 * @returns
 */
export function getProductsTagsListStat(product_id) {
  return getJson({
    url: `/api/v2/${product_id}/tags/stat`,
  });
}

/**
 * 产品的标签-单个-新建
 * @param {int} product_id
 * @param {string} content_type 内容类型。默认为 post
 * @param {string} name 名称
 * @param {string} visible 可见范围。默认为 all
 * @param {array} setting 配置
 * @returns
 */
export function postProductsTagsAdd(product_id, data = {}) {
  return postJSON({
    url: `/api/v2/${product_id}/tags`,
    data,
  });
}

/**
 * 产品的标签-单个-更新
 * @param {int} product_id
 * @param {int} tag_id
 * @param {string} content_type 内容类型。默认为 post
 * @param {string} name 名称
 * @param {string} visible 可见范围。默认为 all
 * @param {array} setting 配置
 * @returns
 */
export function postProductsTagsRenew(product_id, tag_id, data = {}) {
  return postJSON({
    url: `/api/v2/${product_id}/tags/${tag_id}`,
    data,
  });
}

/**
 * 产品的标签-单个-删除
 * @param {int} product_id
 * @param {int} tag_id
 * @returns
 */
export function deleteProductsTagsDel(product_id, tag_id) {
  return deleteJson({
    url: `/api/v2/${product_id}/tags/${tag_id}`,
  });
}

/**
 * 产品的内容的标签-增加
 * @param {int} product_id
 * @param {string} content_type 内容类型。默认为 post
 * @param {string} content_id 内容ID
 * @param {string} visible 可见范围。默认为 all
 * @param {int} tag_id 标签ID
 * @returns
 */
export function postListTagsAdd(product_id, content_type, content_id, tag_id) {
  return postJSON({
    url: `/api/v2/${product_id}/${content_type}/${content_id}/tags/${tag_id}`,
  });
}

/**
 * 产品的内容的标签-删除
 * @param {int} product_id
 * @param {string} content_type 内容类型。默认为 post
 * @param {string} content_id 内容ID
 * @param {string} visible 可见范围。默认为 all
 * @param {int} tag_id 标签ID
 * @returns
 */
export function postListTagsDel(product_id, content_type, content_id, tag_id) {
  return deleteJson({
    url: `/api/v2/${product_id}/${content_type}/${content_id}/tags/${tag_id}`,
  });
}

/**
 * 产品的内容的标签-批量获取
 * @param {int} product_id
 * @param {string} content_type 内容类型。默认为 post
 * @param {array} content_id 内容ID集合
 * @returns
 */
export function postListTagsAllGet(product_id, content_type, content_ids) {
  return postJSON({
    url: `/api/v2/${product_id}/${content_type}/tags`,
    data: {
      content_type,
      content_ids,
    },
  });
}

/**
 * 获取企业的产品的内容的标签-批量获取
 * @param {int} enterprise_id
 * @param {string} content_type 内容类型。默认为 post
 * @param {array} ids 产品内容ID集合
 * @returns
 */
export function postFeedbackCenterTagsAllGet(enterprise_id, content_type, ids) {
  return postJSON({
    url: `/api/v2/${enterprise_id}/feedback_center/${content_type}/tags`,
    data: {
      ids,
    },
  });
}

/**
 * 产品的标签-批量排序
 * @param {int} product_id
 * @param {string} content_type 内容类型。默认为 post
 * @param {array} ids 产品内容ID集合
 * @returns
 */
export function postTagsSort(productId, data) {
  return postJSON({
    url: `/api/v2/${productId}/tags/sort`,
    data,
  });
}

/** tags end */

/** topic start */

/**
 * 产品-话题-反馈列表-获取
 * @param {int} productId
 * @param {string} tab 标签 newest: 最新; popular：热门 。默认为 newest。
 * @param {string} scene 场景 brief: 简短-首页; 。默认为 brief
 * @param {int} per_page 每页记录数 默认为20
 * @param {string} prev_page_id ID分页获取列表 上一页的起始ID
 * @param {string} next_page_id ID分页获取列表 下一页的起始ID
 * @returns
 */
export function getProductIDTopicsList({ productId, topicId, tab, next_page_id, per_page, prev_page_id }) {
  return getJson({
    url: `/api/v3/${productId}/roadmap/topics/${topicId}/posts`,
    data: {
      tab,
      next_page_id,
      per_page,
      prev_page_id,
    },
  });
}

/**
 * 企业话题列表获取
 * @param {int} enterprise_id
 * @param {string} tab 标签 newest: 最新; popular：热门 。默认为 newest。
 * @param {string} scene 场景 brief: 简短-首页; 。默认为 brief
 * @returns
 */
export function getEnterpriseIDTopics({ enterprise_id, tab, scene }) {
  return getJson({
    url: `/api/v2/enterprise/${enterprise_id}/topics`,
    data: {
      tab,
      scene,
    },
  });
}

/**
 * 企业产品话题新增
 * @param {int} product_id
 * @param {string} title 标题
 * @param {string} desc 描述
 * @param {string} type 类型
 * @returns
 */
export function postProductIDTopic({ product_id, title, desc, type }) {
  return postJSON({
    url: `/api/v2/${product_id}/topics`,
    data: {
      title,
      desc,
      type,
    },
  });
}

/**
 * 企业产品话题更新
 * @param {int} productId
 * @param {string} title 标题
 * @param {string} desc 描述
 * @param {string} type 类型
 * @returns
 */
export function postProductIDTopicID({ productId, topicsId, title, desc, type }) {
  return postJSON({
    url: `/api/v2/${productId}/topics/${topicsId}`,
    data: {
      title,
      desc,
      type,
    },
  });
}

/**
 * 企业产品话题删除
 * @param {int} productId
 * @param {int} topicsId 话题ID
 * @returns
 */
export function deleteProductIDTopicID({ productId, topicsId }) {
  return deleteJson({
    url: `/api/v2/${productId}/topics/${topicsId}`,
    data: {},
  });
}


/**
 * 产品-话题-列表-获取
 * @param {int} productId
 * @param {string} tab newest(默认): 最新; popular：热门; recommend: 推荐; recommend_popular:推荐+热门 。
 * @param {string} keyword 搜索词 有搜索词时 tab 无效
 * @param {string} type 状态 doing: 进行中；done:已完成。
 * @param {string} scene 场景 brief(默认): 简短; detail: 详细。
 * @param {int} per_page 每页记录数 默认为20
 * @param {string} prev_page_id ID分页获取列表 上一页的起始ID
 * @param {string} next_page_id ID分页获取列表 下一页的起始ID
 * @returns
 */
export function getProductTopicsList({
  productId,
  tab,
  keyword,
  type,
  scene,
  per_page,
  prev_page_id,
  next_page_id,
}) {
  const data = filterParams({
    productId,
    tab,
    keyword,
    type,
    scene,
    per_page,
    prev_page_id,
    next_page_id,
  });
  return getJson({
    url: `/api/v3/${productId}/roadmap/topics/`,
    data,
  });
}

/**
 * 企业产品-话题-列表-获取
 * @param {int} enterpriseId
 * @param {int} ids 产品内容ID集合 [{"product_id":1,"content_ids":1},{"product_id":2,"content_ids":[1,2]}]
 * @returns
 */
export function getEnterprisePostTopics({ enterpriseId, ids }) {
  return postJSON({
    url: `/api/v2/${enterpriseId}/enterprise/post/topics`,
    data: {
      ids,
    },
  });
}

/**
 * 单个产品-话题-列表-获取
 * @param  productId
 * @param  ids 产品内容ID集合 [{"product_id":1,"content_ids":1},{"product_id":2,"content_ids":[1,2]}]
 * @returns
 */
export function postProductPostTopics({ productId, content_ids, content_type }) {
  return postJSON({
    url: `/api/v3/${productId}/post/roadmap/topics`,
    data: {
      content_ids,
      content_type,
    },
  });
}


/**
 * 产品-话题配置-更新
 * @returns
 */
export function postProductTopicSetting({ productId, status, sort_ids }) {
  const data = {};
  if (status) data.status = status;
  if (sort_ids) data.sort_ids = sort_ids;
  return postJSON({
    url: `/api/v2/${productId}/topics/setting`,
    data,
  });
}
/** topic end */

/**
 * 初始化search中的各种info
 */
export function initUserInfo(productId, search) {
  return getJson({ url: `/login/init_user_info/${productId}${search}` });
}

/**
 * ce 分类
 */
export {
  getApiV2EnterpriseSubject,
  getApiV2EnterpriseSubjectCategories,
} from './v2/enterprise/subjects';

/**
 * 话题列表
 */
export {
  getApiTopicsPostsPopular,
} from './v2/topics/list';
/**
 * 话题
 */
export {
  postApiV2Topics,
} from './v2/topics';

/**
 * ce 运营位配置
 */
export {
  getApiEnterpriseOperateSettings,
} from './v2/enterprise/settings';

/**
 * ce feed流
 */
export {
  getApiV2FeedbackCenterIndexFeed,
  getApiV2FeedbackCenterIndexHotPanel,
  getApiV2FeedbackCenterIndexRecent,
  getApiV2FeedbackCenterIndexHotPosts,
} from './v2/feedback_center/index';

/**
 * ce 排行榜
 */
export {
  getApiV2FeedbackCenterLeaderboardUser,
  getApiV2FeedbackCenterLeaderboardProduct,
  getApiV2FeedbackCenterLeaderboardReply,
} from './v2/feedback_center/leaderboard';

/**
 * ce 用户动态
 */
export {
  getApiV2FeedbackCenterStaffInfo,
  getApiV2FeedbackCenterStaffTimeline,
} from './v2/feedback_center/staff';

/**
 * 运营广告位属性配置
 */
export {
  getApiV1ProductsAttribute,
  postApiV1ProductsAttribute,
} from './v1/products/promotionbit';

/**
 * 用户信息
 */
export {
  getApiV1UserInfo,
} from './v1/user/info';
/**
 * 用户信息
 */
export {
  postApiV1UsersAccount,
} from './v1/user/account';

/**
 * 用户信息
 */
export {
  postV1UserActionDoneBatch,
  postV1UserActionCountInit,
} from './v1';

/**
 * ce 搜索结果
 */
export {
  getApiV2FeedbackCenterSearch,
  getApiV2FeedbackCenterSearchMobile,
} from './v2/feedback_center/search';

/**
 * 部门列表
 */
export {
  getApiV2FeedbackCenterDept,
} from './v2/feedback_center/dept';

/**
 * 项目接口
 */

export {
  getApiV2FeedbackCenterProductAll,
  getApiV2FeedbackCenterProductList,
  getApiV2FeedbackCenterProductMy,
} from './v2/feedback_center/product';

/**
 * 分类列表
 */
export {
  getApiV2FeedbackCenterCategoryList,
} from './v2/feedback_center/category';

/**
 * 反馈 中话题相关
 */
export {
  deleteApiV2PostTopics,
  postApiV2PostTopics,
} from './v2/post/topic';
export {
  getApiV2PostLabelFeat,
} from './v2/post/label';
/**
 * 反馈 中话题相关
 */
export {
  postApiV2AccountContactVerifySend,
  postApiV2AccountContactVerifyCode,
  getApiV2AccountContact,
} from './v2/account/contact';



export {
  getApiV3PostsReplies,
  getApiV3PostsRepliesReplies,
  getApiV3PostsRepliesById,
  postApiV3PostModification,
} from './v3/posts';

export {
  getApiV3RoadmapCategories,
} from './v3/roadmap/categories';

export {
  getApiV3RoadmapTopics,
  getApiV3RoadmapTopicById,
  postApiV3RoadmapTopicsById,
  getApiV3RoadmapTopicSearch,
  postApiV3RoadmapTopics,
} from './v3/roadmap/topics';

export {
  postApiV3RoadmapGroup,
  postApiV3RoadmapGroupById,
  deleteApiV3RoadmapGroup,
} from './v3/roadmap/group';


export {
  getApiV3RoadmapSettingDashboard,
  postApiV3RoadmapSetting,
  getApiV3RoadmapSettingStatus,
} from './v3/roadmap/setting';
