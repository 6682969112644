/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import Form from '@tencent/ten-design-react/lib/form';
import Select from '@tencent/ten-design-react/lib/select';
import Input from '@tencent/ten-design-react/lib/input';
import { Button } from 'tdesign-react';
import { getTapdIWantContent } from 'components/util';
import { POST_TYPE } from 'components/constants/app';
import PropTypes from 'prop-types';

// eslint-disable-next-line no-useless-escape
const ORIGIN_REG = new RegExp(`(${location.origin}[a-zA-Z0-9\/]+)`, 'g');

function formatTapdIWantContent(post, isWrap) {
  const content = getTapdIWantContent(post.content);
  const isWrapCont = isWrap ? content.tapdContS : content.tapdCont;
  return post.type === POST_TYPE.I_WANT ? isWrapCont : post.content;
}

function transferSafeHtml(content) {
  return content
    .replace(/\n/g, '<br>')
    .replace(ORIGIN_REG, '<a href="$1" target="_blank">$1</a>');
}

function createDefaultContent(post, productId) {
  return `用户反馈：${formatTapdIWantContent(post, true)}
图片数量：${post.images.length}张
反馈链接：${window.location.origin}/products/${productId}/post/${post.id}`;
}

export const TapdFromCreate = ({
  projectOptions,
  projectTypes,
  cancel,
  submit,
  productId,
  post,
}) => {
  const [currentProject, updateCurrentProject] = useState('');
  const [currentType, updateCurrentType] = useState('');
  const [initalTitle, updateInital] = useState(false);
  const [title, updateTitle] = useState(getTapdIWantContent(post.content).title?.slice(0, 100));
  const [content, updateContent] = useState(createDefaultContent(post, productId));
  const [loading, updateLoading] = useState(false);
  const titleErrorText = initalTitle && !title ? '标题不能为空' : '';

  function checkFormData() {
    if (!title || loading) {
      updateInital(true);
      return;
    }

    const formData = {
      type: currentType,
      project: currentProject,
      content: transferSafeHtml(content),
      title,
    };

    const next = submit(formData);

    if (next && next.then) {
      updateLoading(true);
      next.then(() => updateLoading(false));
    }
  }

  useEffect(() => {
    if (projectOptions.length) updateCurrentProject(projectOptions[0].key);
  }, [projectOptions]);

  useEffect(() => {
    if (projectTypes.length) updateCurrentType(projectTypes[0].key);
  }, [projectTypes]);

  return (
    <>
      <Form layout="inline" labelWidth={76} className="tapd-confirm-body__form">
        <Form.Item label="TAPD 项目" className="tapd-confirm-body__form-inline">
          <Select
            loading={!projectOptions.length}
            options={projectOptions}
            value={currentProject}
            optionKey={data => data.key}
            optionText={data => data.text}
            onChange={v => updateCurrentProject(v)}
          />
        </Form.Item>
        <Form.Item label="事项类别" className="tapd-confirm-body__form-inline">
          <Select
            options={projectTypes}
            value={currentType}
            optionKey={data => data.key}
            optionText={data => data.text}
            onChange={v => updateCurrentType(v)}
          />
        </Form.Item>
      </Form>
      <Form labelWidth={76} className="tapd-confirm-body__form">
        <Form.Item label="事项标题" required error={titleErrorText}>
          <Input
            placeholder="请输入任务的标题"
            value={title}
            onBlur={() => updateInital(true)}
            onChange={e => updateTitle(e.target.value)}
          />
        </Form.Item>
        <Form.Item label="事项内容">
          <Input.Textarea
            className="tapd-confirm-body__form-textarea"
            placeholder="请输入任务的标题"
            value={content}
            onChange={e => updateContent(e.target.value)}
          />
        </Form.Item>
      </Form>

      <div className="tapd-confirm-body__form-footer">
        <Button onClick={cancel} theme="minor" size="small">
          取消
        </Button>
        <Button
          size="small"
          theme="primary"
          loading={loading}
          onClick={checkFormData}
        >
          保存
        </Button>
      </div>
    </>
  );
};

TapdFromCreate.propTypes = {
  projectOptions: PropTypes.array,
  projectTypes: PropTypes.array,
  cancel: PropTypes.func,
  submit: PropTypes.func,
  post: PropTypes.object,
};
