/* eslint-disable no-param-reassign */
/* eslint-disable camelcase, max-len, no-plusplus, no-mixed-operators */

import React, { Component, createRef } from 'react';
import { StickyTool } from '@tencent/coral-design';
import { message, Modal as ConfirmModal } from '@tencent/ten-design-react';
import BasePagePc from 'components/ui/layout/base-page-pc';
import { Modal } from 'components/modal';
import '@tencent/ten-design-react/dist/ten.css';
import classnames from 'classnames';
import { getRandomPlaceholder, hasRelated, verificationCode, verificationCodePromise } from 'components/util';
import { ComProblem } from 'components/com-problem';
import {
  post_api_posts_replies,
  post_api_posts_upload_images,
  get_api_profile_detail,
  get_api_team_new,
  get_api_post_popup_content,
  post_api_products_posts,
  get_api_startup_preview,
  get_api_faqs_categories_list,
  get_api_faqs_categories_list_categoryId,
  getApiNotificationSetting,
  getApiNotificationSubscribeStatus,
  postApiNotificationSubscribe,
  apiGetAuthorizedApp,
  getApiV3RoadmapSettingStatus,
} from 'components/api';
import { href_get_id, getUrlParam } from 'components/href-helper';
import { ModalReport } from 'components/secure-report';
import { PostItemPc, PostListSolution } from 'components/post-item';
import { PoweredBy } from 'components/poweredby';
import { PostPopUps, PostPopupWxSubscripe } from 'components/post-pop-ups';
import { DesktopTippedModal } from 'components/tipped';
import { GoodQuestionPopup } from 'components/post-label';
import { METHOD } from 'components/notification/constants';
import { POST_TYPE } from 'components/constants/app';
import imgBanner from 'components/assets-image/banner/prod_home_banner.jpg';

import { Banner } from './common/banner';
import { SideInfo } from './common/side-info';
import { PostButton } from './common/post-button';
import { TeamBlog } from './common/team-blog';
import { HotTopics } from 'components/hot-topics';
import { Promotionbit } from 'components/promotionbit';

import 'components/css/pc/reset.less';
import './style.less';
import { LoadMoreHook } from '../../components/load-more/load-more-hook';
import { IconEmtydata } from '../../components/IconSystem';
import { TapdConfirm } from 'components/tapd-confirm';
import { hasTapdPlatform } from 'components/tapd-confirm/util';
import { ENTERPRISE_VERSION } from 'components/constants/enterprise';
import { HotRoadmap } from 'components/hot-roadmap';
import TxcUrlScheme from 'components/url-scheme';


const I_WANT_TYPE = 'i_want_it';
const POST_BTN_CLS = 'product-home__post-btn';
class App extends Component {
  constructor(props) {
    super(props);
    this.productId = href_get_id().productId;
    this.preview = getUrlParam('startup');
    this.state = {
      accountInfo: {}, // 登录信息
      userData: {}, // 用户信息
      productData: {}, // 产品信息
      showReward: false, // 是否显示打赏弹窗
      postMsg: {}, // 帖子信息
      faqList: [], // 常见问题列表
      // is_loading: true,
      showScrollTop: false, // 显示回到顶部
      showCommentBox: false, // 是否展示评论框
      showReplyBox: false,    // 是否展示回复框
      userDetail: {}, // 用户发帖赞同信息
      blogList: [],   // 博客列表
      hasStory: false, // 是否有我们的故事
      showPostPopup: false, // 是否展示发帖弹窗
      postPopup: {}, // 发帖弹窗内容
      showReportModal: false, // 是否展示举报弹窗
      reportReplyId: null,
      showSuccessPostPopup: false, // 是否展示微信授权弹窗
      successPostUrl: '',  // 微信授权弹窗二维码URL
      successPostStatus: 'pending', // 微信授权弹窗状态

      currentReplys: [], // 同一时间统一帖子的评论
      currentReplyId: null, // 当前评论/回复 的帖子ID
      showLogin: false, // 显示登录弹窗
      submitPostSuccess: 0,  // 更新帖子
      bannerUrl: '', // banner路径
      isDefaultBanner: false, // 是否是默认banner

      showGoodQuestion: false, // 第一次标记好问题提示
      faqClassifyList: [], // 问题分类
      nextFaqClassifyList: [], // 二级问题分类
      classifyFaqList: [], // 分类问题列表
      faqList_Loading: true,
      newPostPlaceholder: getRandomPlaceholder(), // 发帖弹窗的文案, 产品希望每次点击能随机一个文案作为彩蛋

      scene: [], // 通知订阅场景
      postedId: null, // 发帖或评论的ID
      postedType: null, // 发帖或评论 reply: 评论 post: 反馈
      method_pc: null, // PC消息通知渠道

      showTapdConfirm: false, // 展示tapd创建弹窗
      tapdConfirmPost: {}, // tapd confirm 选中的post

      showTopic: false, // 话题设置开关
      showSendPostBtnInHeader: false,
    };

    this.onScroll = this.onScroll.bind(this);
    this.onUploadImage = this.onUploadImage.bind(this);
    this.onSubmitSuccess = this.onSubmitSuccess.bind(this);
    this.handlepostPopupSuccessRetry = this.handlepostPopupSuccessRetry.bind(this);


    // 触发 我要发言 按钮切换位置的高度
    this.targetTop = 0;
    this.getSetting = false;
    this.isWxTimeing = true;
    this.firstGetPostLiked = true; // 普通用户端第一次请求帖子的点赞信息时
    this.firstGetReplyLiked = true; // 普通用户第一次请求帖子评论的点赞信息时
    this.subscribeLimiteTimes = 0; // 微信订阅次数，上限为30

    const { searchParams } = new URL(location.href); // 举报页面跳转判断
    this.productInformId = searchParams.get('product_inform_id') ? searchParams.get('product_inform_id') : ''; // 是否带有举报项目ID
    this.autoShowPostPopup = !!searchParams.get('auto_show_post_popup'); // 登录后，自动调起发言弹框

    this.postListRef = createRef();

    this.authorizedInfo = { // 平台授权相关信息
      apps: {},
      platforms: [],
    };
  }

  componentDidMount() {
    this.init();
    this.isPreview();
    this.getPlatformData();
    this.setTargetTop();
    window.addEventListener('scroll', this.onScroll, false);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.userData !== this.state.userData) {
      // 右侧用户数据
      this.getProfileDetail();
    }
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.onScroll, false);
  }

  // 设置post-btn 的初始高度
  setTargetTop = () => {
    // 判断我要发言按钮是否需要放到顶部导航栏
    const postBtn = document.getElementsByClassName(POST_BTN_CLS)[0];
    this.targetTop = postBtn.getBoundingClientRect().top;
  }

  // 获取静态信息
  init() {
    const { productId } = this;

    // 我们的故事
    get_api_team_new({ productId }).then(res => this.setState({ hasStory: Boolean(res.data.team_list.length) }));

    // 获取问题分类
    get_api_faqs_categories_list({ productId }).then((res) => {
      const { data } = res;
      const newList = [...data.list];
      if (data.info.total >= 1) {
        data.list.forEach((item, i) => {
          const isDefault = item.code === 'default_node' && item.title === '';
          if (isDefault) {
            newList[i].title = '默认分类';

            if (item.child_info.length === 0 && isDefault) {
              newList.splice(i, 1);
            }
          }
        });

        this.setState({
          faqClassifyList: newList,
        });
      }
    });

    // 获取产品的话题设置
    getApiV3RoadmapSettingStatus({ productId })
      .then((res) => {
        if (res.status === 0) {
          this.setState({ showTopic: res.data?.status === 'on' });
        }
      });
  }

  /**
     * 获取二级问题分类
     * @param {string} productId
     * @param {string} categoryId
     * @param {string} type  获取的是问题分类还是分类下的问题列表
     */
  getNextFaqClassify(productId, categoryId, type) {
    let categories_only = 0;
    if (type === 'getCategory') categories_only = 1;
    return get_api_faqs_categories_list_categoryId({ productId, categoryId, categories_only }).then((res) => {
      const { data } = res;

      if (data.info.type === 'category') {
        this.setState({
          nextFaqClassifyList: data.list,
        });
      } else {
        if (data.list.length > 8) {
          data.list.length = 8;
        }

        this.setState({
          faqList_Loading: false,
          classifyFaqList: data.list,
        });

        if (type === 'getCategory') {
          this.setState({
            nextFaqClassifyList: [],
          });
        }
      }
    });
  }

  // 预览模式
  isPreview() {
    const { productId } = this;
    if (this.preview === '1') {
      get_api_startup_preview({ productId }).then(res => this.setState({ postPopup: res.data, showPostPopup: true }));
    }
  }

  previewError() {
    if (this.preview === '1') {
      message.error('预览模式下不能完成该操作');
      return false;
    }

    return true;
  }

  // 获取指定用户的信息
  getProfileDetail() {
    const { productId, state } = this;
    // 未登录时无用户信息，不发起请求
    if (!state.userData.user_id) {
      return;
    }
    get_api_profile_detail({ productId, userId: state.userData.user_id })
      .then((res) => {
        this.setState({ userDetail: res.data });
      });
  }

  // 打赏
  onRewardCLick(postItem) {
    if (!this.checkAndShowModalLogin()) {
      return;
    }
    this.setState({
      showReward: true,
      postMsg: postItem,
    });
  }

  // 关闭打赏弹窗
  onCloseReward() {
    this.setState({ showReward: false });
  }

  // 打赏成功
  onTippedSuccess() {
    const { state } = this;

    this.setState({ showReward: false });

    message.success('打赏成功');
    // 更新已打赏的用户信息
    const postIds = [state.postMsg.id];
    this.postListRef.current.asyncGetTipBatchInfo(postIds);
  }


  // 更多操作
  onClickMenu({ opt, val }, replyId = null, postId, postType, msgType = '') {
    const messageSuccess = (msgText) => {
      let msg = msgText;
      switch (msg) {
      case 'onhide':
        msg = '隐藏成功';
        break;
      case 'offhide':
        msg = '取消隐藏成功';
        break;
      case 'offhidereply':
        msg = '回复成功并取消隐藏';
        break;
      case 'offhidepost':
        msg = '评论成功并取消隐藏';
        break;
      case 'ontop':
        msg = '置顶成功';
        break;
      case 'offtop':
        msg = '取消置顶成功';
        break;
      case 'onlock':
        msg = '禁止回复成功';
        break;
      case 'offlock':
        msg = '允许回复成功';
        break;
      case 'onblock':
        msg = '加入黑名单成功';
        break;
      case 'offblock':
        msg = '取消黑名单成功';
        break;
      case 'ongood':
        msg = '标记好问题成功';
        break;
      case 'offgood':
        msg = '取消好问题成功';
        break;
      default:
        break;
      }
      message.success(msg);
    };

    if (opt === 'report') {
      this.showReport(replyId, true);
      this.postId = postId;
      return;
    }

    if (!replyId) {
      this.postListRef.current.asyncOperation({ postId, opt, val })
        .then(() => {
          messageSuccess(`${val}${opt}${msgType}`);

          if (`${val}${opt}` === 'ongood') {
            this.firstSetGoodQuestion();
          }
        })
        .catch((err) => {
          this.onErrorHint(err.message.replace('<br/>', ','));
        });
    } else {
      this.postListRef.current.asyncOperation({ postId, replyId, opt, val })
        .then(() => {
          messageSuccess(`${val}${opt}`);
        });
    }
  }

  firstSetGoodQuestion() {
    const isFirstSetIsGood = localStorage.getItem('isGood');

    if (!isFirstSetIsGood) {
      localStorage.setItem('isGood', 'is_good');
      this.setState({
        showGoodQuestion: true,
      });
    }
  }

  /**
     * 显示举报弹窗
     * @param [replyId] 如果是举报对象是评论，则带上评论id
     * */
  showReport(replyId, isShow = false) {
    this.setState({
      showReportModal: isShow,
      reportReplyId: replyId,
    });
  }

  // 举报
  reportPosts(value) {
    const { state, postId } = this;

    const clearState = () => {
      message.success('举报成功');
      this.showReport(null);
    };

    this.postListRef.current.asyncReport({ postId, replyId: state.reportReplyId, value })
      .then(clearState)
      .catch(err => message.error(err.message));
  }

  /**
     * 删除帖子
     * @param postId
     */
  onPostsDelete(postId) {
    this.postListRef.current.asyncDeletePostOrReply(postId)
      .then(() => {
        message.success('删除成功');
      });
  }

  // 删除评论
  onDeleteComment(replyId, postId) {
    this.postListRef.current.asyncDeletePostOrReply(postId, replyId)
      .then(() => {
        message.success('删除成功');
      });
  }

  // 解决ScrollTop 组件bug
  onScroll() {
    const scrollY = window.scrollY || window.pageYOffset;
    const { state } = this;
    if (scrollY > 250 && !state.showScrollTop) {
      this.setState({ showScrollTop: true });
    } else if (scrollY < 250 && state.showScrollTop) {
      this.setState({ showScrollTop: false });
    }
    // 判断我要发言按钮是否需要放到顶部导航栏
    const postBtn = document.getElementsByClassName(POST_BTN_CLS)[0];

    if (postBtn) {
      // 是否有顶部返回 tips
      const hasRelated = this.getRelated();
      const tipHeight = hasRelated ? 26 : 0;
      const headerHeight = 60;
      const postBtnHeight = 36;
      const { top } = postBtn.getBoundingClientRect();
      if (!this.targetTop) {
        this.targetTop = top;
      }
      if (!state.showSendPostBtnInHeader && top < (tipHeight + headerHeight - postBtnHeight)) {
        this.setState({ showSendPostBtnInHeader: true });
        this.targetTop = scrollY;
      }

      if (state.showSendPostBtnInHeader && scrollY < this.targetTop) {
        this.setState({ showSendPostBtnInHeader: false });
      }
    }
  }

  // 点击评论
  onClickComment(clickAgain = false, postId) {
    if (!this.checkAndShowModalLogin()) {
      return;
    }

    if (!clickAgain) {
      this.setState((preState) => {
        let { currentReplys } = preState;

        if (postId !== preState.currentReplyId) {
          currentReplys = [];
        }
        return {
          showCommentBox: postId,
          currentReplys,
        };
      });
    } else {
      this.setState({ showCommentBox: false });
    }
  }

  // 点击回复
  onClickReply(clickAgain = false, reply_id, postId) {
    if (!this.checkAndShowModalLogin()) {
      return;
    }

    if (!clickAgain) {
      this.setState((preState) => {
        let { currentReplys } = preState;

        if (postId !== preState.currentReplyId) {
          currentReplys = [];
        }

        return {
          showReplyBox: reply_id,
          currentReplys,
        };
      });
    } else {
      this.setState({ showReplyBox: false });
    }
  }

  // 上传图片， 评论，回复的
  onUploadImage(data, onProgress) {
    return post_api_posts_upload_images({ productId: this.productId, data, onProgress });
  }

  // 回复帖子，对帖子评论
  onCommentSubmit({ value, images = [], replyId = null, cancelHidden, postId, postType }) {
    const { productId } = this;
    let msgType;
    if (cancelHidden) {
      msgType = replyId ? 'reply' : 'post';

      this.onClickMenu({ opt: 'hide', val: 'off' }, null, postId, postType, msgType);
    }
    const replyData = {
      productId,
      postId,
      text: value,
      images,
      parent_reply_id: replyId,
    };

    const success = () => {
      if (!msgType) {
        if (!replyId) {
          message.success('评论成功');
        } else {
          message.success('回复成功');
        }
      }

      this.setState({
        showReplyBox: false,
        showCommentBox: false,
      });
    };

    const getAsyncReplies = async (res) => {
      // 更新评论
      // 不能直接取 post_posts_replies 接口的返回值
      // 其结构与 get_posts_replies 不一致
      if (res.status === 1) {
        message.error(res.message);
        return Promise.reject(res.message);
      }

      const { latest_reply_id } = res.data;
      const p1 = this.postListRef.current.asyncUpdateReplies(postId, latest_reply_id);
      const p2 = this.judgeWhetherToSubscribe('reply', latest_reply_id);
      const p3 = this.judgeSubscribeType(latest_reply_id, 'reply');
      const [p1Res, p2Res, p3Res] = await Promise.all([p1, p2, p3]);
      if (p2Res && this.state.method_pc === METHOD.wx_subscribe) {
        this.checkSubscribeStatus('reply', latest_reply_id, p2Res);
      }
    };

    return this.verificationCode()
      .then(({ randstr, ticket }) => post_api_posts_replies(Object.assign(replyData, {
        captcha_randstr: randstr,
        captcha_ticket: ticket,
      })))
      .then((res) => {
        success();
        getAsyncReplies(res)
          .catch(err => message.error(err.message));
      })
      .catch(err => message.error(err.message));
  }

  verificationCode = () => {
    const { userData, productData } = this.state;
    if (!userData?.is_admin && productData.is_slider_captcha_enabled) {
      return verificationCodePromise('captcha_post');
    }

    return Promise.resolve({});
  }

  // 发帖
  onPostSubmit(text, images, user_contacts, categories, type = POST_TYPE.FEATURE, topic) {
    const { productId } = this;

    if (!this.previewError()) {
      return;
    }

    const abuse_product_id = this.productInformId;

    const params = { productId, text, images, user_contacts, categories, type };

    if (this.productInformId) {
      params.abuse_product_id = abuse_product_id;
    }

    if (topic?.id) {
      params.topic_id = topic.id;
    }

    return this.verificationCode()
      .then(({ randstr, ticket }) => post_api_products_posts(Object.assign(params, {
        captcha_randstr: randstr,
        captcha_ticket: ticket,
      })))
      .then((res) => {
        const txcUrlScheme = new TxcUrlScheme();
        txcUrlScheme.sendPost({ productId, contacts: user_contacts || [] });
        return res;
      })
      .then(this.onSubmitSuccess)
      .catch((err) => {
        message.error(err.message);
        return Promise.reject(err);
      });
  }

  onSubmitSuccess = async (res) => {
    if (res.status !== 0) return Promise.reject(res.message);
    const { id } = res.data;
    message.success('发帖成功');
    this.setState(({ submitPostSuccess }) => ({ submitPostSuccess: submitPostSuccess + 1 }));
    await this.judgeSubscribeType(id, 'post');
  };
  /**
     * 判断用户展示订阅类型
     * @param {String} objId 评论或反馈的id
     * @param {String} type 通知订阅场景; reply: 评论 post: 反馈
     */
  async judgeSubscribeType(objId, type) {
    try {
      const overviewRes = await getApiNotificationSetting({ productId: this.productId });
      if (overviewRes.status !== 0) throw new Error(overviewRes.message);
      const { scene, method } = overviewRes.data;
      const method_pc = method.pc;
      if (!method_pc) {
        this.onShowPostPopup();
        return null;
      }
      this.setState({
        scene,
        method_pc,
        postedId: objId,
        postedType: type,
      });
      const subscribeRes = this.judgeWhetherToSubscribe(type, objId);
      switch (method_pc) {
      case METHOD.wx_subscribe:
        if (subscribeRes) this.checkSubscribeStatus(type, objId, subscribeRes);
        if (!subscribeRes) this.onShowPostPopup();
        break;
      case METHOD.email:
        this.setState({ showSuccessPostPopup: true });
        this.onShowPostPopup();
        break;
      default:
        break;
      }
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  /**
     * 判断是否给微信用户展示订阅消息
     * @param {String} type 通知订阅场景; reply: 评论 post: 反馈
     * @param {String} objId 评论或反馈的id
     * @return {String} 订阅地址; null: 没有订阅地址
     */
  judgeWhetherToSubscribe(type, objId) {
    const { userData, scene } = this.state;
    try {
      if (userData.is_admin) return null;
      const currScene = type === 'reply' ? 'replied_reply_by_admin' : 'replied_post_by_admin';
      if (!scene.find(item => item === currScene)) return null;
      const redirect = `embed/${this.productId}/subscribe/${objId}?scene=${currScene}`;
      return `${location.origin}/login/mobile/weixin?tucao_redirect_uri=${encodeURIComponent(redirect)}`;
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  // 邮件的确认逻辑
  handlEmailConfirm() {
    const { state } = this;
    postApiNotificationSubscribe({
      productId: this.productId,
      scene: state.postedType === 'reply' ? 'replied_reply_by_admin' : 'replied_post_by_admin',
      type: state.postedType,
      id: state.postedId,
      channel: state.method_pc,
    })
      .then(() => {
        setTimeout(() => {
          this.setState({
            showSuccessPostPopup: false,
          });
          this.onShowPostPopup();
        }, 100);
      })
      .catch((err) => {
        setTimeout(() => {
          this.setState({
            showSuccessPostPopup: false,
          });
          this.onShowPostPopup();
        }, 100);
        console.log(err);
      });
  }

  /**
     * 轮询确认订阅状态
     * @param {String} type 通知订阅场景; reply: 评论 post: 反馈
     * @param {String} objId 评论或反馈的id
     * @param {String} successPostUrl 微信订阅url
     */
  checkSubscribeStatus(type, objId, successPostUrl) {
    this.isWxTimeing = true;
    this.setState({
      successPostStatus: 'pending',
      showSuccessPostPopup: true,
      successPostUrl,
    });
    this.statusParam = {
      scene: type === 'reply' ? 'replied_reply_by_admin' : 'replied_post_by_admin',
      object_type: type,
      object_id: objId,
    };

    this.subscribeLimiteTimes = 0;
    this.getSubscribeStatus();
  }

  getSubscribeStatus() {
    this.wxTimer = setTimeout(async () => {
      if (!this.isWxTimeing) return;

      try {
        if (++this.subscribeLimiteTimes > 40) throw new Error('subscribeLimiteTimes');
        const res = await getApiNotificationSubscribeStatus(this.productId, this.statusParam);

        if (res.status !== 0) throw res;

        if (res.data.status === 'subscribed') {
          this.setState({
            successPostStatus: 'subscribed',
          });

          setTimeout(() => {
            this.setState({
              showSuccessPostPopup: false,
            });
            this.onShowPostPopup();
          }, 2000);
          return;
        }

        this.getSubscribeStatus();
      } catch (error) {
        this.setState({
          successPostStatus: 'error',
        });
        this.subscribeLimiteTimes = 0;
      }
    }, 1000);
  }

  handlepostPopupSuccessRetry() {
    this.setState({ successPostStatus: 'pending' });
    this.getSubscribeStatus();
  }

  // 显示发帖弹窗
  onShowPostPopup(isShow = false) {
    const { productId } = this;

    if (!this.previewError()) {
      return;
    }

    if (!this.checkAndShowModalLogin()) {
      return;
    }

    if (isShow) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }

    if (!this.getSetting) {
      // 发帖弹窗内容
      this.getSetting = true;
      get_api_post_popup_content(productId).then(res => this.setState({ postPopup: res.data, showPostPopup: isShow }));
      return;
    }
    this.setState({ showPostPopup: isShow, newPostPlaceholder: getRandomPlaceholder() });
  }
  // 错误提示
  onErrorHint(errorText) {
    message.error(errorText);
  }

  /**
     * 判断用户是否登录
     * @return {boolean}
     * */
  checkAndShowModalLogin() {
    if (!this.isLogin()) {
      // 如果用户未登录，拉起登录弹窗
      this.setState({ showLogin: true });
      return false;
    }

    return true;
  }

  /**
     * 判断用户是否登录
     * */
  isLogin() {
    const { state } = this;
    return Boolean(state.userData.user_id);
  }

  // 打赏错误处理
  onTippedError(err) {
    this.onErrorHint(err.message);
    this.onCloseReward();
  }

  // 关闭微信弹窗
  onCloseWxPopup() {
    this.setState({ showSuccessPostPopup: false });
    clearTimeout(this.wxTimer);
    this.isWxTimeing = false;
  }

  hasPostsList(obj) {
    return Boolean(Object.keys(obj).length);
  }

  getUserInfo(res) {
    try {
      if (res.message === '未登录') throw new Error(res.message);
      this.setState({ userData: res.data }, () => {
        // 登录后，根据参数自动调起发言弹框：需要拉取问题分类, 执行方法调用
        if (this.autoShowPostPopup) {
          this.onShowPostPopup(true);
        }
      });
      if (this.productInformId) this.setState({ showPostPopup: true });
    } catch {
      if (this.productInformId) this.setState({ showLogin: true });
    }
  }

  getTapdEntryStatus() {
    // 腾讯内部产品 & 自己是管理员，展示tapd绑定入口
    return this.state.productData.is_tencent_product
      && this.state.userData.is_admin;
  }

  showTapdConfirm(item) {
    if (hasTapdPlatform(this.authorizedInfo.platforms)) {
      this.setState({ showTapdConfirm: true, tapdConfirmPost: item });
    } else {
      // 未绑定弹窗
      ConfirmModal.confirm({
        title: '关联 TAPD 平台',
        content: '尚未关联TAPD，是否立即前往关联',
        width: 640,
        onConfirm: () => {
          window.open(`/dashboard/products/${this.productId}?tab=2`);
          ConfirmModal.confirm({
            width: 640,
            title: '提示',
            content: '是否完成关联授权?',
            confirmText: '已完成授权',
            cancelText: '失败重试',
            onConfirm: () => window.location.reload(),
          });
        },
      });
    }
  }

  closeTapdConfirm() {
    this.setState({ showTapdConfirm: false });
  }

  // 获取是否有顶部提示条
  getRelated = () => {
    const { productData } = this.state;
    return hasRelated(productData);
  }

  // 获取关联的平台应用，用以获取关联事项列表
  getPlatformData() {
    return apiGetAuthorizedApp(this.productId).then((res) => {
      let apps = {};
      res.data.platforms.forEach((p) => {
        apps = { ...apps, [p.id]: [] };
        res.data.apps.forEach((a) => {
          if (p.id === a.platform_id) apps[p.id].push(a);
        });
      });

      this.authorizedInfo = { platforms: res.data.platforms, apps };
    })
      .catch(() => {
        this.authorizedInfo = { platforms: [], apps: {} };
      });
  }

  // 标签选中状态更新
  changePostsList(postId, isTop, tag) {
    this.postListRef.current.asyncTagsList({ postId, isTop, tag });
  }

  setProductInfo = (res) => {
    if (!res.data) return;

    this.setState({
      productData: res.data,
      bannerUrl: res.data.banner_images.header_image_pc || imgBanner,
      isDefaultBanner: !res.data.banner_images.header_image_pc,
    });
  }

  render() {
    const { state, productId } = this;
    const { faqList } = state;
    if (faqList.length > 8) {
      faqList.length = 8;
    }
    const hasFaqList = Boolean(state.faqClassifyList.length);
    const showBlog = Boolean(state.blogList.length) || state.hasStory;
    const showTapdEntry = this.getTapdEntryStatus();
    // 是否有顶部返回 tips
    const hasRelated = this.getRelated();
    return (
      <BasePagePc
        className={'product-home'}
        isShowLogin={state.showLogin}
        onLoginClose={() => this.setState({ showLogin: false })}
        getFaqs={res => this.setState({ faqList: res.data })}
        getTeamblog={res => this.setState({ blogList: res.data })}
        getAccountInfo={res => this.setState({ accountInfo: res.data })}
        getUserInfo={res => this.getUserInfo(res)}
        getProductInfo={this.setProductInfo}
      >
        <div className="product-home">
          <Banner bannerUrl={state.bannerUrl} isDefaultBanner={state.isDefaultBanner} />
          <div className="layerContainer clearfix">
            <div className="main">
              {
                hasFaqList
              && <ComProblem
                list={state.faqList}
                faqClassifyList={state.faqClassifyList}
                nextFaqClassifyList={state.nextFaqClassifyList}
                classifyFaqList={state.classifyFaqList}
                isPc={true}
                target={'_blank'}
                faqList_Loading={state.faqList_Loading}
                getNextFaqClassify={(id, type) => this.getNextFaqClassify(productId, id, type)}
                setFaqListLoading={() => this.setState({ faqList_Loading: true })}
                resetFaqList={() => this.setState({ classifyFaqList: [] })}
                resetNextFaqClassifyList={() => this.setState({ nextFaqClassifyList: [] })}
              />
              }
              <PostListSolution
                ref={this.postListRef}
                productId={this.productId}
                productInfo={state.productData}
                userData={state.userData}
                batch={true}
                submitPostSuccess={state.submitPostSuccess}
              >
                {({ noticeList, postsList, pagination, isLoading, rewardInfo, tipBatchList, iWant, tagArrList }) => {
                  if (!this.postListRef.current) {
                    return null;
                  }
                  if (this.postListRef.current.isEmptyList() && (iWant && !iWant.id)) {
                    return (
                      <div className="post_empty">
                        <IconEmtydata />
                        <span className="font">期待你的声音</span>
                      </div>
                    );
                  }

                  const renderPostItem = (item, postType) => {
                    const postId = item.id;
                    const isTop = item.is_top;

                    item.user = state.userData;

                    const show_comment_textarea = Boolean(state.showCommentBox === postId);

                    return (
                      <PostItemPc
                        {...item}
                        key={item.id}
                        showTopic={state.showTopic}
                        userData={state.userData}
                        productData={state.productData}
                        show_comment_textarea={show_comment_textarea}
                        showReplyBox={state.showReplyBox}
                        rewardInfo={rewardInfo}
                        tipBatchList={tipBatchList}
                        tagArrList={tagArrList}
                        changePostsList={this.changePostsList.bind(this, postId, isTop)}
                        onClickReply={(clickAgain, reply_id) => this.onClickReply(clickAgain, reply_id, postId)}
                        onClickComment={clickAgain => this.onClickComment(clickAgain, postId)}
                        onClickLike={() => {
                          if (!this.checkAndShowModalLogin()) {
                            return;
                          }
                          this.postListRef.current.asyncLiked({ postId });
                        }}
                        onRepliesLiked={(likedObj, replyId) => {
                          if (!this.checkAndShowModalLogin()) {
                            return;
                          }
                          this.postListRef.current.asyncLiked({ postId, replyId });
                        }}
                        onRewardCLick={() => this.onRewardCLick(item)}
                        onCheckLogin={() => this.checkAndShowModalLogin()}
                        onClickMenu={({ opt, val }, f_reply_id) => this.onClickMenu({
                          opt,
                          val,
                        }, f_reply_id, postId, postType)}
                        onClickDelete={() => this.onPostsDelete(postId, postType)}
                        onCommentSubmit={({ value, images, replyId, cancelHidden }) => this.onCommentSubmit({
                          value,
                          images,
                          replyId,
                          cancelHidden,
                          postId,
                          postType,
                        })}
                        onUploadImage={this.onUploadImage}
                        onDeleteComment={replyId => this.onDeleteComment(replyId, postId)}
                        onCommentError={error => this.onErrorHint(error.message)}
                        tapdEntry={showTapdEntry}
                        onClickTapd={() => this.showTapdConfirm(item)}
                      />
                    );
                  };
                  const newPostList = [].concat(postsList);
                  // 预期iwant插入在第三条后。由于有置顶
                  const iWantIndex = 3;
                  // 如果存在iwant
                  if (iWant && iWant.id) {
                    const iWantData = {
                      ...iWant,
                      type: I_WANT_TYPE,
                    };
                    newPostList.splice(iWantIndex - noticeList.length, 0, iWantData);
                  }
                  return (
                    <>
                      <div className="main_post">
                        {// 置顶帖子
                          !isLoading && noticeList.map(item => renderPostItem(item, 'notice'))
                        }
                        {// 普通帖子列表
                          !isLoading && newPostList.map(item => renderPostItem(item))
                        }

                        {/* 切换标签时候使用的 loading */}
                        {isLoading && <LoadMoreHook />}

                      </div>

                      {/* 滚动加载时候使用的 loading */}
                      {pagination.has_more_pages && (
                        <LoadMoreHook onShow={() => this.postListRef.current.loadMore()} />
                      )}

                      {/* EOL */}
                      {!isLoading && !pagination.has_more_pages && (
                        <div className="load-all">
                          <span className="no-more">已经显示全部</span>
                        </div>
                      )}

                    </>

                  );
                }}
              </PostListSolution>

            </div>
            <div className="side">

              {/* 基础信息 */}
              <SideInfo {...state.userDetail} userData={state.userData} >
                <PostButton
                  onClick={() => this.onShowPostPopup(true)}
                  className={classnames(POST_BTN_CLS, {
                    [`${POST_BTN_CLS}--header`]: state.showSendPostBtnInHeader,
                    [`${POST_BTN_CLS}--has-related`]: hasRelated,
                  })}
                />
              </SideInfo>

              {/* 热门话题 */}
              {/* 热门话题在更新roadmap后取消了 */}
              {state.showTopic && <HotRoadmap productId={this.productId} />}
              {
                showBlog && (
                  <TeamBlog
                    blogList={state.blogList}
                    hasStory={state.hasStory}
                  />
                )
              }

              {/* 运营广告 */}
              {<Promotionbit />}

              <PoweredBy
                cid={state.accountInfo.cid}
                txEnterpriseUrl={`/enterprise/${state.accountInfo.cid}/home`}
              />
            </div>
          </div>

          {/* 回到顶部 */}
          {state.showScrollTop && <StickyTool className="layout-sticky-tool" />}

          {// 发帖弹窗
            state.showPostPopup
          && <PostPopUps
            {...state.postPopup}
            preview={this.preview}
            userData={state.userData}
            showIwant={state.productData?.i_want_status}
            showPostPopupSuccess={state.showSuccessPostPopup}
            textPlaceholder={state.newPostPlaceholder}
            postPopupSuccess={{
              url: state.successPostUrl,
              status: state.successPostStatus,
              onRetry: this.handlepostPopupSuccessRetry,
            }}
            methodPc={state.method_pc}
            handlEmailConfirm={this.handlEmailConfirm.bind(this)}
            onClose={() => this.onShowPostPopup() || state.showSuccessPostPopup && this.onCloseWxPopup()}
            onSubmit={this.onPostSubmit.bind(this)}
            onSubmitSuccess={this.onSubmitSuccess}
            onUploadImage={this.onUploadImage.bind(this)}
            onError={err => message.error(err)}
            onTextEnterError={err => this.onErrorHint(err.message)}
          />
          }
          {// 打赏弹窗
            state.showReward
          && <DesktopTippedModal
            avatar={state.postMsg.avatar_url}
            productId={this.productId}
            postId={state.postMsg.id}
            onClose={() => this.onCloseReward()}
            onSuccess={() => this.onTippedSuccess()}
            onError={error => this.onTippedError(error)}
          />
          }

          {// 举报弹窗
            state.showReportModal
          && <Modal onClose={() => this.setState({ showReportModal: false })}>
            <ModalReport onReport={value => this.reportPosts(value)} />
          </Modal>

          }

          {// 评论成功后的各类型弹窗
            !state.showPostPopup && state.showSuccessPostPopup
          && <PostPopupWxSubscripe
            userData={state.userData}
            batchReply={state.postPopup.batchReply}
            avatar={state.userData.avatar}
            url={state.successPostUrl}
            status={state.successPostStatus}
            methodPc={state.method_pc}
            handlEmailConfirm={this.handlEmailConfirm.bind(this)}
            onRetry={this.handlepostPopupSuccessRetry}
            onClose={() => this.onCloseWxPopup()}
          />
          }


          {// 第一次标记好问题提示
            state.showGoodQuestion
          && <Modal onClose={() => this.setState({ showGoodQuestion: false })}>
            <GoodQuestionPopup
              onClose={() => this.setState({ showGoodQuestion: false })}
              isPc={true}
            />
          </Modal>

          }
          {
            showTapdEntry
          && <TapdConfirm
            user={state.userData}
            productId={this.productId}
            post={state.tapdConfirmPost}
            open={state.showTapdConfirm}
            onClose={() => this.closeTapdConfirm()}
            onSuccess={(tapdUrl) => {
              this.closeTapdConfirm();
              setTimeout(window.open, 1000, tapdUrl);
            }}
          />
          }
        </div>
      </BasePagePc>
    );
  }
}

export { App };
