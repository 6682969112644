import React, { FC, useState, Fragment } from 'react';
import { RoadmapItemProps } from './types';
import SupportBtn, { TYPE } from 'components/ui/common/support-btn';
import { ImageDesktop } from 'components/comment-image';
import { ImageAlbum } from '@tencent/coral-design';
import './index.less';
import classNames from 'classnames';
import { postsTime } from 'components/util';
import { dangerouslySetInnerHTML } from 'components/render-rich-text';
import { Popup } from 'tdesign-react';


const cls = 'roadmap-item';

const RoadmapItem: FC<RoadmapItemProps> = ({
  /** 点赞数 */
  likeCount,
  /** 标题 */
  title,
  /** 讨论数 */
  contentCount,
  /** 阅读数 */
  readCount,
  /** 顶按钮点击事件 */
  onLikeClick,
  /** 是否已经点赞 */
  liked,
  /** 是否有管理员回复 */
  showAdminAnswerIcon,
  /** 展示功能投票icon */
  showIcon,
  /** 显示编辑时间 */
  showEditEditTime,
  /** 编辑时间 */
  editTime,
  /** 官方回复 */
  answerPost,
  extraContent,
  showBorder,
  isLikeLoading,
  disabledLike,
  topicId,
  productId,
  editTimeHoverText,
}) => {
  /** 用来隐藏弹窗 */
  const DEFAULT_ABLUM_IMG = -1;
  const [albumImgIndex, setAlbumImgIndex] = useState(DEFAULT_ABLUM_IMG);
  const userUrl = `/products/${productId}/profile/${answerPost?.userId}`;
  return (
    <div className={cls}>
      <SupportBtn
        onClick={() => {
          if (!disabledLike) {
            onLikeClick();
          }
        }}
        count={likeCount}
        isLiked={liked}
        type={TYPE.PC_ROADMAP}
        // zeroText="顶"
        isLoading={isLikeLoading}
        disabled={disabledLike}
      />
      <div className={classNames(`${cls}__main`, {
        [`${cls}__main--border`]: showBorder,
      })}>

        <div className={`${cls}__content`}>
          <a href={`/products/${productId}/topic-detail/${topicId}/`} target="_blank" className={classNames(`${cls}__title`, {
            [`${cls}__title--icon`]: showIcon,
          })} rel="noreferrer" >{title}</a>
          <div className={`${cls}__info-wrap`}>
            {showEditEditTime && editTime && (
              <div className={`${cls}__info`}>
                <Popup trigger="hover" showArrow content={editTimeHoverText} disabled={!editTimeHoverText}>
                  <span className={`${cls}__edit-time`} >{postsTime(editTime)}</span>
                </Popup>
              </div>
            )}
            {extraContent && extraContent()}
          </div>
        </div>
        <div className={`${cls}__info`}>
          {(
            <Popup trigger="hover" showArrow content="官方回复" disabled={!showAdminAnswerIcon}>
              <span
                className={classNames(`${cls}__discuss`, {
                  [`${cls}__discuss--admin`]: showAdminAnswerIcon,
                })}
              >
                {contentCount}讨论&nbsp;&nbsp;
              </span>
            </Popup>
          )}
          {(<span className={`${cls}__read`} >{readCount}次阅读&nbsp;&nbsp;</span>)}
        </div>

        {answerPost && (
          <div className={`${cls}__answer`}>
            <a href={userUrl}><img src={answerPost.userAvatar} alt="官方回复头像" className={`${cls}__admin-avatar`} /></a>

            <div className={`${cls}__answer-main`}>
              <div className={`${cls}__answer-info`}>
                <p>
                  <a href={userUrl} className={`${cls}__answer-user`}>{answerPost.userName}</a>

                  <span className={`${cls}__answer-time`}>{postsTime(answerPost.createdAtTimestamp)}</span>
                </p>
                <span className={`${cls}__admin-tag`}>官方回复</span>
              </div>
              <div className={`${cls}__answer-content`} dangerouslySetInnerHTML={dangerouslySetInnerHTML(answerPost.content)} />
              {!!answerPost.images.length && (
                <Fragment>
                  <ImageDesktop imgList={answerPost.images} handleImagesClick={setAlbumImgIndex} />
                  {albumImgIndex !== DEFAULT_ABLUM_IMG && (<ImageAlbum
                    imageList={answerPost.images.map(img => img.original_url)}
                    previewList={answerPost.images.map(img => img.thumb_url)}
                    showPreview={true}
                    showIndex={albumImgIndex}
                    onClose={() => setAlbumImgIndex(DEFAULT_ABLUM_IMG)}
                  />)}
                </Fragment>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default RoadmapItem;
