import batchTopicLike from 'components/api/batch-done';
import { useEffect, useState } from 'react';
import useEffectOnce from './useEffectOnce';

interface UseLikeParams {
  /** 是否已赞 */
  defaultLiked?: boolean;
  /** 赞数 */
  defaultCount: number;
  /** 更新赞的promise，resolve 更新赞，reject 保持原样 */
  likeApi: (liked: boolean) => Promise<any>;
  /** 是否需要加载lieked状态， 不传则自动读取defaultLiked */
  needToBeLoadLiked?: boolean;
  topicId: number;
  productId: string;
}

/**
 * 管理点赞的数量、加载、状态等,
 * 默认异步方法resolved 即赞成功， reject即失败
 */
export const useLike = (props: UseLikeParams) => {
  const { defaultLiked, defaultCount, likeApi } = props;
  /** 是否已赞 */
  const [isLoading, setIsLoading] = useState(false);
  /** 赞数 */
  const [count, setCount] = useState(defaultCount);
  /** 是否已赞 */
  const [liked, setLiked] = useState(defaultLiked || false);


  // 默认赞数更新时更新赞数
  useEffect(() => {
    if (defaultCount === count) return;
    setCount(defaultCount);
  }, [defaultCount]);

  // 批量请求点赞状态
  useEffectOnce(() => {
    if (props.needToBeLoadLiked) {
      batchTopicLike.getTopic({ productId: props.productId, topicId: props.topicId })
        .then((res: boolean) => {
          setLiked(res);
        });
    }
  });


  const updateLike = () => {
    setIsLoading(true);
    likeApi(liked)
      .then(() => {
        setCount(liked ? count - 1 : count + 1);
        setLiked(!liked);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        return err;
      });
  };

  return { isLoading, count, liked, updateLike };
};
