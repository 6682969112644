import React, { useEffect, useState } from 'react';
import { getProductId } from 'components/util';
import {
  getApiV1ProductsAttribute,
  PostApiV1ProductsAttributeRespData,
} from 'components/api/v1/products/promotionbit';
import { ABtestByTimestamp } from 'components/ABtest/ABtestByTimestamp';
import { ATTRIBUTE_TYPE } from 'components/constants/app';
import { Collect } from 'components/report';
import classnames from 'classnames';
import txc from './img/txc.png';
import wjhome from './img/wj-home.png';
import wj360 from './img/wj360.png';

import './style.less';

const cls = 'promotionbit';
const AD_STATUS = {
  ON: 'on',
  OFF: 'off',
};

export const Promotionbit = () => {
  const [isSet, setIsSet] = useState<boolean>(false);
  const [getAbTest, setGetAbTest] = useState<any>();
  const [data, setData] = useState<PostApiV1ProductsAttributeRespData[]>([]);
  const productId = getProductId();

  useEffect(() => {
    const AbTestData = ABtestByTimestamp([
      {
        material: {
          type: '0',
          isShow: true,
          imgers: wj360,
          links: 'https://wj.qq.com/360?utm_source=txc&utm_medium=banner&utm_campaign=360',
        },
        range: 25,
      },
      {
        material: {
          type: '1',
          isShow: true,
          imgers: wjhome,
          links: 'https://wj.qq.com/index.html?utm_source=txc&utm_medium=ad&utm_campaign=index',
        },
        range: 25,
      },
      {
        material: {
          type: '',
          isShow: false,
          imgers: txc,
          links: 'https://txc.qq.com/',
        },
        range: 50,
      },
    ]);
    if (AbTestData.isShow) {
      new Collect({ ea: 'exposure', ev: AbTestData.type, ec: '产品社区首页-PC端', el: '广告运营位' }).report();
    }
    setGetAbTest(AbTestData);
  }, []);

  useEffect(() => {
    getApiV1ProductsAttribute({ productId, type: ATTRIBUTE_TYPE.AD_STATUS })
      .then((res) => {
        if (res.status === 0) {
          setIsSet(res.data === AD_STATUS.ON);
        }
      });
  }, [productId]);

  const handleToggleAd = () => {
    // postApiV1ProductsAttribute({
    //   productId,
    //   type: AD_TYPE,
    //   data: !isSet ? AD_STATUS.ON : AD_STATUS.OFF,
    // })
    //   .then((res) => {
    //     if (res.status === 0) {
    //       setData(res.data);
    //       setIsSet(res.data.ad_status === AD_STATUS.ON);
    //     }
    //   });
    setIsSet(!isSet);
  };

  const handleClickCollect = () => {
    new Collect({ ea: 'click', ev: getAbTest.type, ec: '产品社区首页-PC端', el: '广告运营位' }).report();
  };

  if (!isSet || !getAbTest.isShow) {
    return null;
  }

  return (
    <div>
      <div className={classnames(`${cls}`, {
        [`${cls}__active`]: !isSet || !getAbTest.isShow,
      })}>
        <a className={`${cls}__pic`} href={getAbTest.links} onClick={handleClickCollect} target="_blank" rel="noreferrer">
          <img className={`${cls}__img`} src={getAbTest.imgers} alt="ad" />
        </a>
        <span className={`${cls}__btn`} onClick={handleToggleAd} onKeyPress={handleToggleAd} role="button" tabIndex={0}>
          <i className={`${cls}__word`}>广告</i>
        </span>
      </div>
    </div>
  );
};
